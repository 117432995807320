import React, { useEffect, useState } from "react";
import "../styles.scss";
import MainLayout from "../../../layouts/MainLayout";
import UsageTrends from "./UsageTrends";
import RatioOfDelivery from "./RatioOfDelivery";
import CreditHistory from "./CreditHistory";
import { useDispatch } from "react-redux";
import { FetchCreditHistory, FetchRatioOfDelivery, FetchUsageTrends } from "../../../redux/actions/reports.action";
import { FetchAllUsers } from "../../../redux/actions/user.action";

const Reports = () => {
  const dispatch = useDispatch();
  const [trendsDateRange, setTrendsDateRange] = useState({ startDate: "", endDate: "" });
  const [deliveryDateRange, setDeliveryDateRange] = useState({ startDate: "", endDate: "" });
  const [historyDateRange, setHistoryDateRange] = useState({ startDate: "", endDate: "" });
  const [trendsUser, setTrendsUser] = useState("");
  const [deliveryUser, setDeliveryUser] = useState("");
  const [historyUser, setHistoryUser] = useState("");

  useEffect(() => {
    dispatch(FetchUsageTrends({ ...trendsDateRange, userId: trendsUser }));
  }, [dispatch, trendsDateRange, trendsUser]);

  useEffect(() => {
    dispatch(FetchRatioOfDelivery({ ...deliveryDateRange, userId: deliveryUser }));
  }, [dispatch, deliveryDateRange, deliveryUser]);

  useEffect(() => {
    dispatch(FetchCreditHistory({ ...historyDateRange, userId: historyUser }));
  }, [dispatch, historyDateRange, historyUser]);

  useEffect(() => {
    dispatch(FetchAllUsers());
  }, [dispatch]);

  return (
    <MainLayout title={"Reports"} isDashboard={false}>
      <UsageTrends setDateRange={setTrendsDateRange} user={trendsUser} setUser={setTrendsUser} />
      <div className="mt-10"></div>
      <RatioOfDelivery setDateRange={setDeliveryDateRange} user={deliveryUser} setUser={setDeliveryUser} />
      <div className="mt-10"></div>
      <CreditHistory setDateRange={setHistoryDateRange} user={historyUser} setUser={setHistoryUser} />
    </MainLayout>
  );
};

export default Reports;
