import React, { useEffect, useState } from "react";
import { DatePicker, Select, Table } from "antd";
import "../styles.scss";
import MainLayout from "../../../layouts/MainLayout";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../../../utils/misc/constants";
import FieldContainer from "../../../components/Inputs/FIeldContainer";
import { FetchCreditLogs } from "../../../redux/actions/credit.action";
import { debounce } from "lodash";
import { FetchAllUsers } from "../../../redux/actions/user.action";

const { RangePicker } = DatePicker;

const CreditManagement = () => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState({ userId: "" });
  const creditLogs = useSelector((state) => state?.credit?.creditLogs);
  const loader = useSelector((state) => state?.credit?.loading);
  const allUsers = useSelector((state) => state?.user?.allUsers);
  const [allUser, setAllUser] = useState([{ value: "", label: "All Users" }]);
  const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" });

  const [defaultCurrent, setDefaultCurrent] = useState(1);
  const [defaultPageSize, setDefaultPageSize] = useState(5);
  const [paginationData, setPaginationData] = useState({
    page: 0,
    size: 5,
  });

  useEffect(() => {
    dispatch(FetchCreditLogs({ ...paginationData, ...dateRange, ...query }));
  }, [dispatch, paginationData, dateRange, query]);

  useEffect(() => {
    dispatch(FetchAllUsers());
  }, [dispatch]);

  useEffect(() => {
    const users = allUsers.map(u => {
      return { value: u?.id, label: u?.fullname }
    });
    users?.unshift({ value: "", label: "All Users" });
    setAllUser(users);
  }, [allUsers])

  const onChange = (date) => {
    if (date) {
      const startDate = date[0]
        ? new Date(date[0]).toISOString().split("T").join(" ")
        : "";
      const endDate = date[1]
        ? new Date(date[1]).toISOString().split("T").join(" ")
        : "";
      setDateRange({ startDate, endDate });
    } else {
      setDateRange({ startDate: "", endDate: "" });
    }
  };

  const columns = [
    {
      title: "Account Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Units",
      dataIndex: "units",
      key: "units",
    },
    {
      title: "Added By",
      dataIndex: "addedBy",
      key: "addedBy",
    },
    {
      title: "Date",
      dataIndex: "sendAt",
      key: "sendAt",
    },
  ];

  const dataSource =
    creditLogs &&
    creditLogs?.content?.length > 0 &&
    creditLogs?.content?.map((row) => {
      return {
        key: row.id,
        name: row?.account?.fullname ?? "-",
        email: row?.account?.email ?? "-",
        units: row?.amount ?? "-",
        addedBy: row?.creditedBy?.fullname ?? "-",
        sendAt: row?.dateAdded ? formatDate(row?.dateAdded) : "-",
      };
    });

  const onShowSizeChange = (current, pageSize) => {
    if (pageSize || current) {
      setDefaultPageSize(pageSize);
      setDefaultCurrent(current - 1);
    } else {
      setDefaultPageSize(5);
      setDefaultCurrent(1);
    }
  };

  const paginationChangeHandler = (current, pageSize) => {
    setPaginationData({
      ...paginationData,
      page: current - 1,
      size: pageSize,
    });
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return (
        <a href={"#/"} className={"text-white"}>
          {"<"}
        </a>
      );
    }
    if (type === "next") {
      return (
        <a href={"#/"} className={"text-white"}>
          {">"}
        </a>
      );
    }
    return originalElement;
  };

  const handleChange = (event) => {
    performSearch(event);
  };

  const performSearch = debounce((value) => {
    if (value.length > 0) {
      setQuery({ userId: value });
    } else {
      setQuery({ userId: "" });
    }
  }, 700);

  return (
    <MainLayout title={"Credit Management"} isDashboard={false}>
      <div className={"text-left"}>
        <h2 className={"text-sm-headline font-bold text-white"}>
          Top up history
        </h2>
      </div>
      <div className={"flex justify-end mb-4 gap-x-4 mt-2"}>
        <RangePicker
          onChange={onChange}
          className={"xl:w-2/5 md:w-3/5 w-full form-field_input_0"}
        />
        <div>
          <FieldContainer>
            <Select
              className={"form-field_input_2"}
              onChange={handleChange}
              placeholder="Select User"
              options={allUser}
            />
          </FieldContainer>
        </div>
      </div>
      <Table
        loading={loader}
        dataSource={dataSource}
        columns={columns}
        scroll={{ x: 800 }}
        pagination={{
          pageSizeOptions: ["5", "10", "20", "30", "40"],
          showSizeChanger: true,
          onShowSizeChange: onShowSizeChange,
          defaultCurrent: defaultCurrent,
          total: creditLogs?.totalElements,
          pageSize: defaultPageSize,
          showTotal: (total) => `Total ${total} items`,
          defaultPageSize: defaultPageSize,
          onChange: paginationChangeHandler,
          itemRender,
        }}
        className={"campaing-table"}
      />
    </MainLayout >
  );
};

export default CreditManagement;
