import React, { useState } from "react";
import "../styles.scss";
import MainLayout from "../../../layouts/MainLayout";
import { useNavigate } from "react-router-dom";
import Campaigns from "./Campaings";
import CampaignDetails from "./CampaignDetails";

const Dashboard = () => {
  const [singleCampaign, setSingleCampaign] = useState(null);
  const navigate = useNavigate();
  const handleCampaign = (campaign) => {
    setSingleCampaign(campaign);
  };

  const handleClick = () => {
    navigate("/send");
  };

  return (
    <MainLayout
      title={"Dashboard"}
      description={"We provide unrivalled service and access to the Nigerian market."}
      handleClick={handleClick}
      isDashboard={true}
    >
      {singleCampaign === null && (
        <Campaigns getCampaign={(campaign) => handleCampaign(campaign)} />
      )}
      {singleCampaign !== null && (
        <CampaignDetails
          campaign={singleCampaign}
          removeCampaign={(value) => setSingleCampaign(value)}
        />
      )}
    </MainLayout>
  );
};

export default Dashboard;
