/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { ReactComponent as Logo } from "../../assets/icons/ripple-logo.svg";
import { ReactComponent as TablerGrid } from "../../assets/icons/icon-tabler-grid.svg";
import { ReactComponent as TablerUser } from "../../assets/icons/icon-tabler-user.svg";
import { ReactComponent as TablerStack } from "../../assets/icons/icon-tabler-stack.svg";
import { ReactComponent as TablerPuzzle } from "../../assets/icons/icon-tabler-puzzle.svg";
import { ReactComponent as TablerMessages } from "../../assets/icons/icon-tabler-messages.svg";
import { ReactComponent as TablerReports } from "../../assets/icons/icon-tabler-reports.svg";
import { ReactComponent as TablerSettings } from "../../assets/icons/icon-tabler-settings.svg";
import { ReactComponent as TablerAdjustments } from "../../assets/icons/icon-tabler-adjustments.svg";
import { ReactComponent as TablerX } from "../../assets/icons/icon-tabler-x.svg";
import "../styles.scss";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import MainLayoutHeader from "./MainLayoutHeader";

const ACTIVE_NAV_CLASS = "w-full text-gray-300 cursor-pointer items-center";
const INACTIVE_NAV_CLASS =
  "w-full text-gray-400 hover:text-gray-300 cursor-pointer items-center";
const ACTIVE_ANCHOR_CLASS =
  "flex items-center focus:outline-none focus:ring-2 focus:ring-white menu is-active";
const ANCHOR_CLASS =
  "flex items-center focus:outline-none focus:ring-2 focus:ring-white menu";

const MainLayout = ({
  children,
  title,
  description,
  handleClick,
  isDashboard,
}) => {
  const navigate = useNavigate();
  const [sideBar, setSideBar] = useState(null);
  const [openSidebar, setOpenSidebar] = useState(null);
  const [closeSidebar, setCloseSidebar] = useState(null);
  const [flag, setFlag] = useState(false);

  const [routes, setRoutes] = useState([
    {
      id: 1,
      path: "/dashboard",
      name: "Dashboard",
      icon: <TablerGrid />,
    },
    {
      id: 2,
      path: "/credit-management",
      name: "Credit Management",
      icon: <TablerPuzzle />,
    },
    {
      id: 3,
      path: "/send",
      name: "Send",
      icon: <TablerStack />,
    },
    {
      id: 4,
      path: "/messages",
      name: "Messages",
      icon: <TablerMessages />,
    },
    {
      id: 5,
      path: "/reports",
      name: "Reports",
      icon: <TablerReports />,
    },
    {
      id: 6,
      path: "/users",
      name: "Users",
      icon: <TablerUser />,
    },
    {
      id: 7,
      path: "/settings",
      name: "Settings",
      icon: <TablerSettings />,
    },
  ]);

  const sidebarHandler = (flag) => {
    setFlag(flag);
    if (flag) {
      sideBar.style.transform = "translateX(0px)";
      openSidebar.classList.add("hidden");
      closeSidebar.classList.remove("hidden");
    } else {
      sideBar.style.transform = "translateX(-260px)";
      closeSidebar.classList.add("hidden");
      openSidebar.classList.remove("hidden");
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("vas_token");
    const user = JSON.parse(localStorage.getItem("vas_user"));

    if (!token) {
      navigate("/");
    }

    if (user && user.role.toUpperCase() !== "ADMIN") {
      const rr = routes.filter((r) => r.id !== 6);
      setRoutes(rr);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    setSideBar(document.getElementById("mobile-nav"));
    setOpenSidebar(document.getElementById("openSideBar"));
    setCloseSidebar(document.getElementById("closeSideBar"));
  }, []);

  useEffect(() => {
    if (sideBar !== null && !flag) {
      sideBar.style.transform = "translateX(-260px)";
    }
  }, [sideBar, flag]);

  const useFindPath = () => {
    const location = useLocation();
    const [currentPath, setCurrentPath] = useState("");

    useEffect(() => {
      setCurrentPath(location.pathname);
    }, [location]);
    return currentPath;
  };

  const path = useFindPath();

  const logout = () => {
    localStorage.removeItem("vas_token");
    localStorage.removeItem("vas_refresh_token");
    localStorage.removeItem("vas_user");
    window.location.reload();
  };

  return (
    <div className={"w-full h-[100vh]"}>
      <div className={"h-full"}>
        <div className={"flex flex-no-wrap h-full bg-neutral-500"}>
          <div
            className={
              "w-64 absolute sm:relative bg-gray-600 shadow md:h-full flex-col justify-between hidden sm:flex"
            }
          >
            <div>
              <div
                className={
                  "h-16 w-full flex items-center px-8 text-white gap-x-2"
                }
              >
                <Logo className="h-8" />
                <p className={"font-bold"}>VAS ACQUICO</p>
              </div>
              <ul className={"mt-12 accordion"}>
                {routes?.map((route) => (
                  <li
                    className={
                      path.includes(route.path)
                        ? ACTIVE_NAV_CLASS
                        : INACTIVE_NAV_CLASS
                    }
                    key={route?.id + "full screen"}
                  >
                    <NavLink
                      to={route.path}
                      className={
                        path.includes(route.path)
                          ? ACTIVE_ANCHOR_CLASS
                          : ANCHOR_CLASS
                      }
                    >
                      {route.icon}
                      <span className={"text-sm ml-2"}>{route.name}</span>
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
            <div className={"px-8 border-t border-gray-700"}>
              <ul
                className={
                  "w-full flex items-center justify-between bg-gray-600"
                }
              >
                <li className={"cursor-pointer text-white w-full"}>
                  <button className={"focus:outline-none w-full"}>
                    <NavLink
                      onClick={logout}
                      className={
                        "flex items-center gap-x-4 w-full pt-4 pb-3 text-sm-regular"
                      }
                    >
                      <TablerGrid />
                      <div>Logout</div>
                    </NavLink>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div
            className={
              "w-64 z-40 absolute bg-gray-600 shadow h-full flex flex-col justify-between sm:hidden transition duration-150 ease-in-out"
            }
            id={"mobile-nav"}
          >
            <button
              className={
                "h-10 w-10 bg-gray-600 absolute right-0 mt-16 -mr-10 flex items-center shadow rounded-tr rounded-br justify-center cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 rounded focus:ring-gray-800"
              }
              onClick={() => sidebarHandler(true)}
            >
              <TablerAdjustments />
            </button>
            <button
              className={
                "hidden h-10 w-10 bg-gray-600 absolute right-0 mt-16 -mr-10 flex items-center shadow rounded-tr rounded-br justify-center cursor-pointer text-white"
              }
              onClick={() => sidebarHandler(false)}
            >
              <TablerX />
            </button>
            <div>
              <div className={"h-16 w-full flex items-center px-8"}>
                <Logo />
              </div>
              <ul className={"mt-12 accordion"}>
                {routes?.map((route) => (
                  <li
                    className={
                      path.includes(route.path)
                        ? ACTIVE_NAV_CLASS
                        : INACTIVE_NAV_CLASS
                    }
                    key={route?.id + "full screen"}
                  >
                    <NavLink
                      to={route.path}
                      className={
                        path.includes(route.path)
                          ? ACTIVE_ANCHOR_CLASS
                          : ANCHOR_CLASS
                      }
                    >
                      {route.icon}
                      <span className={"text-sm ml-2"}>{route.name}</span>
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
            <div className={"px-8 border-t border-gray-700"}>
              <ul
                className={
                  "w-full flex items-center justify-between bg-gray-600"
                }
              >
                <li className={"cursor-pointer text-white w-full"}>
                  <button className={"focus:outline-none w-full"}>
                    <NavLink
                      onClick={logout}
                      className={
                        "flex items-center gap-x-4 w-full pt-4 pb-3 text-sm-regular"
                      }
                    >
                      <TablerGrid />
                      <div>Logout</div>
                    </NavLink>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div
            className={
              "container mx-auto py-10 md:w-4/5 w-11/12 px-6 main-content"
            }
          >
            <MainLayoutHeader
              title={title}
              description={description}
              triggerClick={handleClick}
              isDashboard={isDashboard}
            />
            <div className={"w-full mt-16"}>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
