import React, { useEffect } from "react";
import "../styles.scss";

const AuthLayout = ({ children }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={"children auth"}>
      <section
        className={
          "flex flex-col items-center justify-center h-[100vh] min-h-screen w-full"
        }
      >
        <div className={"sm:w-2/3 w-4/5 bg-neutral-500 auth-frame"}>
          <div className={"w-full h-full lg:flex"}>
            <div className={"lg:block hidden w-1/2"}>
              <div
                className={
                  "left-pain h-full flex flex-col p-10 text-left text-white justify-center"
                }
              >
                <h2
                  className={
                    "lg:text-l-headline text-sm-headline font-bold mb-4"
                  }
                >
                  VAS ACQUICO CAMPAIGN
                </h2>
                <p>
                  We provide unrivalled service and access to the Nigerian
                  market.
                </p>
                <p className={"mt-4"}>
                  We bring innovation to the telecom industry by providing
                  immense value to our customers, partners & content providers
                  both locally and internationally.
                </p>
              </div>
            </div>
            <div className={"lg:w-1/2 w-full h-full overflow-hidden"}>
              {children}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AuthLayout;
