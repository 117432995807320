import { Types } from "../actions/credit.action";

const INITIAL_STATE = {
  loading: false,
  creditLogs: {},
};

export const creditReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.CREDIT_LOGS_START:
      return { ...state, loading: true };
    case Types.CREDIT_LOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        creditLogs: action.creditLogs,
      };
    case Types.CREDIT_LOGS_END:
    default:
      return state;
  }
};
