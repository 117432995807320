import { Button as Btn, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Back from "../../../assets/imgs/back.png";
import Button from "../../../components/Buttons/Button";
import {
  FetchCampaignDetails,
  FetchCampaignSummary,
} from "../../../redux/actions/messaging.action";
import { flCapital, formatDate } from "../../../utils/misc/constants";
import "../styles.scss";
import CampaignMessages from "./CampaignMessages";

const LEFT_COL = "border-b border-l border-r border-gray-600 py-2 text-center";
const RIGHT_COL = "border-b border-r border-gray-600 py-2 text-center";

const CampaignDetails = ({ campaign, removeCampaign }) => {
  const dispatch = useDispatch();
  const campaignDetails = useSelector((state) => state?.sms?.campaignDetails);
  const campaignSummary = useSelector((state) => state?.sms?.campaignSummary);
  const loader = useSelector((state) => state?.sms?.loading);
  const [campaignId, setCampaignId] = useState(null);
  useEffect(() => {
    if (campaign) {
      dispatch(FetchCampaignDetails(campaign));
      dispatch(FetchCampaignSummary(campaign));
    }
  }, [dispatch, campaign]);

  // const handleDownload = (campaign) => {
  //   console.log(campaign);
  // };

  return (
    <>
      {loader && <Spin size="large" className="spinner" />}
      {!loader && (
        <>
          {campaignId === null && (
            <>
              <div className={"text-left flex justify-between items-center"}>
                <div className={"flex gap-x-4 items-center"}>
                  <Btn
                    onClick={() => removeCampaign(null)}
                    className={"border-none px-0"}
                  >
                    <img src={Back} alt={"Back"} className={"h-6"} />
                  </Btn>
                  <h2 className={"text-sm-headline font-bold text-white"}>
                    Campaign
                  </h2>
                </div>
                <div className="flex items-center gap-x-4">
                  {/* <Button
                    css={"w-auto py-2 messages-btn"}
                    text={"Download"}
                    type={"button"}
                    onClick={() => handleDownload(campaign)}
                    state={"PRIMARY"}
                  /> */}
                  <Button
                    css={"w-auto py-2 messages-btn"}
                    text={"View Messages"}
                    type={"button"}
                    onClick={() => setCampaignId(campaign)}
                    state={"PRIMARY"}
                  />
                </div>
              </div>
              <div
                className={
                  "flex flex-wrap justify-between mt-5 items-center text-left"
                }
              >
                <div
                  className={
                    "lg:w-1/2 w-full leading-7 text-sm-regular text-white"
                  }
                >
                  <div className={"w-full flex"}>
                    <div className={"w-1/3 font-bold"}>Batch Name:</div>
                    <div className={"w-2/3"}>{campaignDetails?.name}</div>
                  </div>
                  <div className={"w-full flex"}>
                    <div className={"w-1/3 font-bold"}>Batch Id</div>
                    <div className={"w-2/3"}>{campaignDetails?.id}</div>
                  </div>
                  <div className={"w-full flex"}>
                    <div className={"w-1/3 font-bold"}>Schedule Date:</div>
                    <div className={"w-2/3"}>
                      {campaignDetails?.scheduledAt
                        ? formatDate(campaignDetails?.scheduledAt)
                        : "-"}
                    </div>
                  </div>
                  <div className={"w-full flex"}>
                    <div className={"w-1/3 font-bold"}>Total:</div>
                    <div className={"w-2/3"}>{campaignDetails?.total}</div>
                  </div>
                  <div className={"w-full flex"}>
                    <div className={"w-1/3 font-bold"}>Scheduled By:</div>
                    <div className={"w-2/3"}>
                      {campaignDetails?.scheduledBy?.fullname ?? "-"}
                    </div>
                  </div>
                </div>
                <div className={"lg:w-1/2 w-full lg:mt-0 mt-4"}>
                  <div
                    className={
                      "w-full flex flex-wrap text-sm-regular text-white"
                    }
                  >
                    <div
                      className={
                        "w-full border text-center py-2 border-gray-600 bg-gray-600 font-bold text-white"
                      }
                    >
                      Progress
                    </div>
                    {campaignSummary &&
                      campaignSummary?.length &&
                      campaignSummary?.map((summary) => (
                        <div
                          key={summary?.campaignStatus}
                          className={"w-full flex"}
                        >
                          <div className={"w-1/2"}>
                            <div className={LEFT_COL}>
                              {flCapital(summary?.campaignStatus)}
                            </div>
                          </div>
                          <div className={"w-1/2"}>
                            <div className={RIGHT_COL}>{summary?.total}</div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </>
          )}
          {campaignId !== null && (
            <CampaignMessages
              campaignId={campaignId}
              removeCampaignId={(value) => setCampaignId(value)}
            />
          )}
        </>
      )}
    </>
  );
};

export default CampaignDetails;
