import { Card } from "antd";
import React, { useEffect, useState } from "react";
import "../styles.scss";

const gridStyle = {
  width: "100%",
  cursor: "pointer",
  textAlign: "left",
  padding: "16px",
};

const menu = [
  {
    id: 1,
    name: "Change Password",
    value: "CHANGE_PASSWORD",
  },
  {
    id: 2,
    name: "Create User",
    value: "CREATE_USER",
  },
];

const SettingsMenu = ({ activeMenuValue, emitMenuValue }) => {
  const [activeMenu, setActiveMenu] = useState("");
  const user = JSON.parse(localStorage.getItem("vas_user"));

  useEffect(() => {
    setActiveMenu(activeMenuValue);
  }, [activeMenuValue]);

  const handleMenu = (m) => {
    setActiveMenu(m);
    emitMenuValue(m);
  };

  return (
    <div className={"w-full rounded-full settings-menu"}>
      <Card>
        {(user && user.role.toUpperCase() !== "ADMIN"
          ? menu.filter((m) => m.value !== "CREATE_USER")
          : menu
        )?.map((m, index) => (
          <Card.Grid
            onClick={() => handleMenu(m?.value)}
            key={m?.id + "menu"}
            className={`${
              index === 0
                ? activeMenu === m?.value
                  ? "settings-menu-item-first bg-[#FEAD02] font-medium"
                  : "settings-menu-item-first text-white"
                : index === menu?.length - 1
                ? activeMenu === m?.value
                  ? "settings-menu-item-last bg-[#FEAD02] font-medium"
                  : "settings-menu-item-last text-white"
                : activeMenu === m?.value
                ? "bg-gray-400 font-medium"
                : "text-white"
            } ${
              user && user.role.toUpperCase() !== "ADMIN"
                ? "!rounded-br-[8px] !rounded-bl-[8px]"
                : ""
            }`}
            style={gridStyle}
          >
            {m?.name}
          </Card.Grid>
        ))}
      </Card>
    </div>
  );
};

export default SettingsMenu;
