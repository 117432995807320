import React, { useEffect, useState } from "react";
import { DatePicker, Table } from "antd";
import "../styles.scss";
import MainLayout from "../../../layouts/MainLayout";
import { useDispatch, useSelector } from "react-redux";
import { FetchMessages } from "../../../redux/actions/messaging.action";
import { formatDate } from "../../../utils/misc/constants";

const { RangePicker } = DatePicker;

const Messages = () => {
  const dispatch = useDispatch();
  const messages = useSelector((state) => state?.sms?.messages);
  const loader = useSelector((state) => state?.sms?.loading);
  const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" });

  const [defaultCurrent, setDefaultCurrent] = useState(1);
  const [defaultPageSize, setDefaultPageSize] = useState(5);
  const [paginationData, setPaginationData] = useState({
    page: 0,
    size: 5,
  });

  useEffect(() => {
    dispatch(FetchMessages({ ...paginationData, ...dateRange }));
  }, [dispatch, paginationData, dateRange]);

  const onChange = (date) => {
    if (date) {
      const startDate = date[0]
        ? new Date(date[0]).toISOString().split("T").join(" ")
        : "";
      const endDate = date[1]
        ? new Date(date[1]).toISOString().split("T").join(" ")
        : "";
      setDateRange({ startDate, endDate });
    } else {
      setDateRange({ startDate: "", endDate: "" });
    }
  };

  const columns = [
    {
      title: "Phone",
      dataIndex: "phonenumber",
      key: "phonenumber",
    },
    {
      title: "Text",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Sent At",
      dataIndex: "sendAt",
      key: "sendAt",
    },
    {
      title: "Delivery Receipt Time",
      dataIndex: "dlrTimestamp",
      key: "dlrTimestamp",
    },
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   key: "id",
    // },
  ];

  const dataSource =
    messages &&
    messages?.content?.length > 0 &&
    messages?.content?.map((row) => {
      return {
        key: row.id,
        id: row?.id ?? "-",
        message: row?.message ?? "-",
        phonenumber: row?.phonenumber ?? "-",
        status: row?.status?.toUpperCase() ?? "-",
        sendAt: row?.sendAt ? formatDate(row?.sendAt) : "-",
        dlrTimestamp: row?.dlrTimestamp ? formatDate(row?.dlrTimestamp) : "-",
      };
    });

  const onShowSizeChange = (current, pageSize) => {
    if (pageSize || current) {
      setDefaultPageSize(pageSize);
      setDefaultCurrent(current - 1);
    } else {
      setDefaultPageSize(5);
      setDefaultCurrent(1);
    }
  };

  const paginationChangeHandler = (current, pageSize) => {
    setPaginationData({
      ...paginationData,
      page: current - 1,
      size: pageSize,
    });
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return (
        <a href={"#/"} className={"text-white"}>
          {"<"}
        </a>
      );
    }
    if (type === "next") {
      return (
        <a href={"#/"} className={"text-white"}>
          {">"}
        </a>
      );
    }
    return originalElement;
  };

  return (
    <MainLayout title={"Messages"} isDashboard={false}>
      <div className={"text-left"}>
        <h2 className={"text-sm-headline font-bold text-white"}>
          All Messages
        </h2>
      </div>
      <div className={"flex justify-end mb-4 gap-x-4 mt-2"}>
        <RangePicker
          onChange={onChange}
          className={"xl:w-2/5 md:w-3/5 w-full form-field_input_0"}
        />
      </div>
      <Table
        loading={loader}
        dataSource={dataSource}
        columns={columns}
        scroll={{ x: 800 }}
        pagination={{
          pageSizeOptions: ["5", "10", "20", "30", "40"],
          showSizeChanger: true,
          onShowSizeChange: onShowSizeChange,
          defaultCurrent: defaultCurrent,
          total: messages?.totalElements,
          pageSize: defaultPageSize,
          showTotal: (total) => `Total ${total} items`,
          defaultPageSize: defaultPageSize,
          onChange: paginationChangeHandler,
          itemRender,
        }}
        className={"campaing-table"}
      />
    </MainLayout>
  );
};

export default Messages;
