import React, { useState } from "react";
import "../styles.scss";
import MainLayout from "../../../layouts/MainLayout";
import SendMenu from "./SendMenu";
import Schedule from "./Schedule";
import QuickSMS from "./QuickSMS";

const Send = () => {
  const [activeMenu, setActiveMenu] = useState("SCHEDULE");
  return (
    <MainLayout title={"Settings"} isDashboard={false}>
      <div className={"flex flex-wrap"}>
        <div className={"xl:w-1/4 lg:w-2/5 w-full p-2"}>
          <SendMenu
            activeMenuValue={activeMenu}
            emitMenuValue={(m) => setActiveMenu(m)}
          />
        </div>
        <div className={"xl:w-3/4 lg:w-3/5 w-full p-2"}>
          <div className={"w-full settings-menu rounded-[8px] text-left p-4"}>
            {activeMenu === "SCHEDULE" && <Schedule />}
            {activeMenu === "QUICK_SMS" && <QuickSMS />}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Send;
