import { getCreditLogs } from "../_api/index";

export const Types = {
  CREDIT_LOGS_START: "CREDIT_LOGS_START",
  CREDIT_LOGS_END: "CREDIT_LOGS_END",
  CREDIT_LOGS_SUCCESS: "CREDIT_LOGS_SUCCESS",
};

export const FetchCreditLogs = (filter) => (dispatch) => {
  dispatch({ type: Types.CREDIT_LOGS_START });
  return new Promise((resolve, reject) => {
    getCreditLogs(filter)
      .then((res) => {
        dispatch({
          type: Types.CREDIT_LOGS_SUCCESS,
          creditLogs: res.data.object,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.CREDIT_LOGS_END });
        reject(err);
      });
  });
};
