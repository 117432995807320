import React, { useState } from "react";
import "../styles.scss";
import MainLayout from "../../../layouts/MainLayout";
import UserList from "./UserList";
import AddCredit from "./AddCredit";
import UserDetails from "./UserDetails";
import AddSenderId from "./AddSenderId";
import SetBalance from "./SetBalance";

const Users = () => {
  const [view, setView] = useState(0);
  const [userId, setUserId] = useState(0);
  const [userName, setUserName] = useState(0);
  const [user, setUser] = useState(0);

  const getUser = (user) => {
    setUser(user);
  }

  return (
    <MainLayout title={"Users"} isDashboard={false}>
      {view === 0
        ? <UserList
          setUserId={setUserId}
          setView={setView}
          setUserName={setUserName}
          getUser={getUser}
        />
        : view === 1 ? <AddCredit
          setView={setView}
          userName={userName}
          userId={userId} />
          : view === 2 ? <UserDetails
            setUserId={setUserId}
            setUserName={setUserName}
            setView={setView}
            user={user} />
            : view === 3 ? <AddSenderId
              setView={setView}
              userName={userName}
              userId={userId}
            /> : <SetBalance
              setView={setView}
              userName={userName}
              userId={userId}
            />}
    </MainLayout>
  );
};

export default Users;
