import {
  downloadCampaignMessages,
  getCampaignDetails,
  getCampaignMessages,
  getCampaignSummary,
  getMessages,
  getSMSBatches,
  scheduleCampaign,
  sendQuickSMS,
} from "../_api/index";

export const Types = {
  SMS_BATCHES_START: "SMS_BATCHES_START",
  SMS_BATCHES_END: "SMS_BATCHES_END",
  SMS_BATCHES_SUCCESS: "SMS_BATCHES_SUCCESS",

  CAMPAIGN_DETAILS_START: "CAMPAIGN_DETAILS_START",
  CAMPAIGN_DETAILS_END: "CAMPAIGN_DETAILS_END",
  CAMPAIGN_DETAILS_SUCCESS: "CAMPAIGN_DETAILS_SUCCESS",

  CAMPAIGN_SUMMARY_START: "CAMPAIGN_SUMMARY_START",
  CAMPAIGN_SUMMARY_END: "CAMPAIGN_SUMMARY_END",
  CAMPAIGN_SUMMARY_SUCCESS: "CAMPAIGN_SUMMARY_SUCCESS",

  SCHEDULE_CAMPAIGN_START: "SCHEDULE_CAMPAIGN_START",
  SCHEDULE_CAMPAIGN_END: "SCHEDULE_CAMPAIGN_END",
  SCHEDULE_CAMPAIGN_SUCCESS: "SCHEDULE_CAMPAIGN_SUCCESS",

  CAMPAIGN_MESSAGES_START: "CAMPAIGN_MESSAGES_START",
  CAMPAIGN_MESSAGES_END: "CAMPAIGN_MESSAGES_END",
  CAMPAIGN_MESSAGES_SUCCESS: "CAMPAIGN_MESSAGES_SUCCESS",

  MESSAGES_START: "MESSAGES_START",
  MESSAGES_END: "MESSAGES_END",
  MESSAGES_SUCCESS: "MESSAGES_SUCCESS",

  SEND_QUICK_SMS_START: "SEND_QUICK_SMS_START",
  SEND_QUICK_SMS_END: "SEND_QUICK_SMS_END",
  SEND_QUICK_SMS_SUCCESS: "SEND_QUICK_SMS_SUCCESS",

  DOWNLOAD_CAMPAIGN_MESSAGES_START: "DOWNLOAD_CAMPAIGN_MESSAGES_START",
  DOWNLOAD_CAMPAIGN_MESSAGES_SUCCESS: "DOWNLOAD_CAMPAIGN_MESSAGES_SUCCESS",
  DOWNLOAD_CAMPAIGN_MESSAGES_END: "DOWNLOAD_CAMPAIGN_MESSAGES_END",
};

export const FetchSMSBatches = (filter) => (dispatch) => {
  dispatch({ type: Types.SMS_BATCHES_START });
  return new Promise((resolve, reject) => {
    getSMSBatches(filter)
      .then((res) => {
        dispatch({
          type: Types.SMS_BATCHES_SUCCESS,
          smsBatches: res.data.object,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.SMS_BATCHES_END });
        reject(err);
      });
  });
};

export const FetchCampaignDetails = (id) => (dispatch) => {
  dispatch({ type: Types.CAMPAIGN_DETAILS_START });
  return new Promise((resolve, reject) => {
    getCampaignDetails(id)
      .then((res) => {
        dispatch({
          type: Types.CAMPAIGN_DETAILS_SUCCESS,
          campaignDetails: res.data.object,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.CAMPAIGN_SUMMARY_END });
        reject(err);
      });
  });
};

export const FetchCampaignSummary = (id) => (dispatch) => {
  dispatch({ type: Types.CAMPAIGN_SUMMARY_START });
  return new Promise((resolve, reject) => {
    getCampaignSummary(id)
      .then((res) => {
        dispatch({
          type: Types.CAMPAIGN_SUMMARY_SUCCESS,
          campaignSummary: res.data.object,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.CAMPAIGN_SUMMARY_END });
        reject(err);
      });
  });
};

export const ScheduleCampaign = (formData, config) => (dispatch) => {
  dispatch({ type: Types.SCHEDULE_CAMPAIGN_START });
  return new Promise((resolve, reject) => {
    scheduleCampaign(formData, config)
      .then((res) => {
        dispatch({
          type: Types.SCHEDULE_CAMPAIGN_SUCCESS,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.SCHEDULE_CAMPAIGN_END });
        reject(err);
      });
  });
};

export const FetchCampaignMessages = (id, filter, query) => (dispatch) => {
  dispatch({ type: Types.CAMPAIGN_MESSAGES_START });
  return new Promise((resolve, reject) => {
    getCampaignMessages(id, filter, query)
      .then((res) => {
        dispatch({
          type: Types.CAMPAIGN_MESSAGES_SUCCESS,
          campaignMessages: res.data.object,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.CAMPAIGN_MESSAGES_END });
        reject(err);
      });
  });
};

export const FetchMessages = (filter) => (dispatch) => {
  dispatch({ type: Types.MESSAGES_START });
  return new Promise((resolve, reject) => {
    getMessages(filter)
      .then((res) => {
        dispatch({
          type: Types.MESSAGES_SUCCESS,
          messages: res.data.object,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.MESSAGES_END });
        reject(err);
      });
  });
};

export const SendQuickSMS = (req) => (dispatch) => {
  dispatch({ type: Types.SEND_QUICK_SMS_START });
  return new Promise((resolve, reject) => {
    sendQuickSMS(req)
      .then((res) => {
        dispatch({ type: Types.SEND_QUICK_SMS_SUCCESS });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.SEND_QUICK_SMS_END });
        reject(err);
      });
  });
};

export const DownloadCampaignMessages = (id, filter, query) => (dispatch) => {
  dispatch({ type: Types.DOWNLOAD_CAMPAIGN_MESSAGES_START });
  return new Promise((resolve, reject) => {
    downloadCampaignMessages(id, filter, query)
      .then((res) => {
        dispatch({
          type: Types.DOWNLOAD_CAMPAIGN_MESSAGES_SUCCESS,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.DOWNLOAD_CAMPAIGN_MESSAGES_END });
        reject(err);
      });
  });
};
