import React from "react";
import { Form, Input, Select, notification } from "antd";
import Email from "../../../assets/icons/email.svg";
import Person from "../../../assets/imgs/person.png";
import Phone from "../../../assets/imgs/phone.png";
import "../styles.scss";
import Button from "../../../components/Buttons/Button";
import { Field, Formik } from "formik";
import * as yup from "yup";
import FieldContainer from "../../../components/Inputs/FIeldContainer";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { InviteUser } from "../../../redux/actions/auth.action";

const values = {
  fullname: "",
  email: "",
  phoneNumber: "",
  role: undefined,
  credit: "",
};

const CreateUser = () => {
  const [form] = Form.useForm();
  const loader = useSelector((state) => state.auth.loading);
  const dispatch = useDispatch();

  const createUser = (values, { resetForm }) => {
    dispatch(InviteUser(values))
      .then(async (res) => {
        notification.success({
          message: "Success",
          description: "User Created Successfully!",
        });
        resetForm();
        form.resetFields();
      })
      .catch((err) => {
        notification.error({
          message: "Error",
          description: err.response.data.errorMsg
            ? err.response.data.errorMsg
            : "An error has ocurred, please try again later",
        });
      });
  };

  return (
    <div className={"w-full"}>
      <h2 className={"text-sm-headline font-bold text-white"}>Create User</h2>
      <div className={"mt-10 w-full flex justify-center"}>
        <div className={"xl:w-1/2 w-full"}>
          <Formik
            initialValues={values}
            onSubmit={createUser}
            enableReinitialize={true}
            validationSchema={validator}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form onFinish={handleSubmit} form={form}>
                <div className={"mb-4"}>
                  <div
                    className={
                      "text-left text-white font-normal text-md-regular mb-2"
                    }
                  >
                    <p>Name</p>
                  </div>
                  <FieldContainer icon={Person} iconPlacementCss={"iconCss"}>
                    <Form.Item
                      name={"fullname"}
                      help={
                        touched.fullname && errors.fullname
                          ? errors.fullname
                          : ""
                      }
                      validateStatus={
                        touched.fullname && errors.fullname
                          ? "error"
                          : "success"
                      }
                    >
                      <Input
                        type={"text"}
                        className={"form-field_input_4"}
                        placeholder={"Enter Name"}
                        value={values?.fullname || ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Form.Item>
                  </FieldContainer>
                </div>
                <div className={"mb-4"}>
                  <div
                    className={
                      "text-left text-white font-normal text-md-regular mb-2"
                    }
                  >
                    <p>Email</p>
                  </div>
                  <FieldContainer icon={Email} iconPlacementCss={"iconCss"}>
                    <Form.Item
                      name={"email"}
                      help={touched.email && errors.email ? errors.email : ""}
                      validateStatus={
                        touched.email && errors.email ? "error" : "success"
                      }
                    >
                      <Input
                        type={"email"}
                        className={"form-field_input_4"}
                        placeholder={"Enter Email"}
                        value={values?.email || ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Form.Item>
                  </FieldContainer>
                </div>
                <div className={"mb-4"}>
                  <div
                    className={
                      "text-left text-white font-normal text-md-regular mb-2"
                    }
                  >
                    <p>Phone Number</p>
                  </div>
                  <FieldContainer icon={Phone} iconPlacementCss={"iconCss"}>
                    <Form.Item
                      name={"phoneNumber"}
                      help={
                        touched.phoneNumber && errors.phoneNumber
                          ? errors.phoneNumber
                          : ""
                      }
                      validateStatus={
                        touched.phoneNumber && errors.phoneNumber
                          ? "error"
                          : "success"
                      }
                    >
                      <Input
                        type={"tel"}
                        className={"form-field_input_4"}
                        placeholder={"E.g +23412341234"}
                        value={values?.phoneNumber || ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Form.Item>
                  </FieldContainer>
                </div>
                <div className={"mb-4"}>
                  <div
                    className={
                      "text-left text-white font-normal text-md-regular mb-2"
                    }
                  >
                    <p>Role</p>
                  </div>
                  <FieldContainer iconPlacementCss={"iconCss"}>
                    <Form.Item
                      name={"role"}
                      help={touched.role && errors.role ? errors.role : ""}
                      validateStatus={
                        touched.role && errors.role ? "error" : "success"
                      }
                    >
                      <Field>
                        {({ form: { setFieldValue } }) => (
                          <Select
                            value={values?.role || undefined}
                            onBlur={handleBlur}
                            className={"form-field_input_2"}
                            onChange={(e) => {
                              setFieldValue("role", e);
                            }}
                            placeholder="Select Role"
                            options={[
                              { value: "ADMIN", label: "Admin" },
                              { value: "STANDARD", label: "Standard" },
                              { value: "SANDBOX", label: "Sandbox" },
                            ]}
                          />
                        )}
                      </Field>
                    </Form.Item>
                  </FieldContainer>
                </div>
                {values?.role === "SANDBOX" ? (
                  <div className={"mb-4"}>
                    <div
                      className={
                        "text-left text-white font-normal text-md-regular mb-2"
                      }
                    >
                      <p>Credit</p>
                    </div>
                    <FieldContainer iconPlacementCss={"iconCss"}>
                      <Form.Item
                        name={"credit"}
                        help={
                          touched.credit && errors.credit ? errors.credit : ""
                        }
                        validateStatus={
                          touched.credit && errors.credit ? "error" : "success"
                        }
                      >
                        <Input
                          type={"number"}
                          className={"form-field_input_2"}
                          placeholder={"Enter Credit"}
                          value={values?.credit || null}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Form.Item>
                    </FieldContainer>
                  </div>
                ) : (
                  <></>
                )}
                <div className={"mb-6 mt-10 flex justify-center"}>
                  <Button
                    css={"w-full"}
                    state={"PRIMARY"}
                    text={"Invite"}
                    type={"submit"}
                    loading={loader}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

const validator = yup.object().shape({
  fullname: yup
    .string()
    .min(6, "Name should be minimum of 6 characters")
    .required("Name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  phoneNumber: yup
    .string()
    .required("Phone number is required")
    .test("is-valid-phone", "Phone number is invalid", (value) => {
      return isValidPhoneNumber(value || "");
    }),
  role: yup.string().required("Role is required"),
  credit: yup.number().when("role", {
    is: "SANDBOX",
    then: () =>
      yup.number()
        .min(1, "Credit should be more than 0")
        .required("Credit is required"),
  }),
});

export default CreateUser;
