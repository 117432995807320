import Http from "../../lib/api";

export const login = (req) => {
  return Http.post("/messaging/authenticate", req);
};

export const activateUser = (params) => {
  return Http.get(
    `/messaging/platform-users/activate-user?email=${params?.email}&activationCode=${params?.activationCode}`
  );
};

export const changePassword = (req) => {
  return Http.post("/messaging/platform-users/change-password", req);
};

export const inviteUser = (req) => {
  return Http.post("/messaging/platform-users/invite", req);
};

export const forgotPassword = (email) => {
  return Http.get(
    `/messaging/platform-users/reset-password?userEmail=${email}`
  );
};

export const resetPassword = ({ email, otp, password }) => {
  return Http.patch(
    `/messaging/platform-users/reset-password/confirm?userEmail=${email}&password=${password}&otp=${otp}`
  );
};

export const getCurrentUser = () => {
  return Http.get("/messaging/logged-in-details");
};

export const getUsers = (filter) => {
  return Http.get(
    filter?.name
      ? `/messaging/platform-users?page=${filter?.page}&size=${filter?.size}&name=${filter?.name}`
      : `/messaging/platform-users?page=${filter?.page}&size=${filter?.size}`
  );
};

export const getAllUsers = () => {
  return Http.get(`/messaging/users`);
};

export const addUserCredit = (req) => {
  return Http.post("/messaging/platform-users/add-credit", req);
};

export const setUserBalance = (req) => {
  return Http.post("/messaging/platform-users/set-balance", req);
};

export const addSenderID = (req) => {
  return Http.post("/messaging/platform-users/add-sender-id", req);
};

export const deleteSenderID = (req) => {
  console.log(req);
  return Http.delete("/messaging/platform-users/remove-sender-id", {
    data: req,
  });
};

export const getSMSBatches = (filter) => {
  return Http.get(
    `/messaging/sms-campaign-batches?page=${filter?.page}&size=${filter?.size}&startDate=${filter?.startDate}&endDate=${filter?.endDate}`
  );
};

export const getCampaignDetails = (id) => {
  return Http.get(`/messaging/sms-campaign-batches/${id}`);
};

export const getCampaignSummary = (id) => {
  return Http.get(`/messaging/sms-campaign-batches/${id}/summary`);
};

export const getCampaignMessages = (id, filter, query) => {
  return Http.get(
    query?.phoneNumber
      ? `/messaging/sms-campaigns/${id}?page=${filter?.page}&size=${filter?.size}&phoneNumber=${query?.phoneNumber}`
      : `/messaging/sms-campaigns/${id}?page=${filter?.page}&size=${filter?.size}`
  );
};

export const downloadCampaignMessages = (id, filter, query) => {
  return Http.get(
    query?.phoneNumber
      ? `/messaging/sms-campaigns/${id}/download?page=${filter?.page}&size=${filter?.size}&phoneNumber=${query?.phoneNumber}`
      : `/messaging/sms-campaigns/${id}/download?page=${filter?.page}&size=${filter?.size}`
  );
};

export const scheduleCampaign = (formData, config) => {
  return Http.post("/messaging/bulk-dispatch", formData, config);
};

export const getMessages = (filter) => {
  return Http.get(
    `/messaging/messages?page=${filter?.page}&size=${filter?.size}&startDate=${filter?.startDate}&endDate=${filter?.endDate}`
  );
};

export const sendQuickSMS = (req) => {
  return Http.post("/messaging/quick-send", req);
};

export const getCreditLogs = (filter) => {
  return Http.get(
    `/messaging/credit-logs?page=${filter?.page}&size=${filter?.size}&startDate=${filter?.startDate}&endDate=${filter?.endDate}&userId=${filter?.userId}`
  );
};

export const getUsageTrends = (filter) => {
  return Http.get(
    filter?.userId
      ? `/messaging/reports/message-trends?startDate=${filter?.startDate}&endDate=${filter?.endDate}&userId=${filter?.userId}`
      : `/messaging/reports/message-trends?startDate=${filter?.startDate}&endDate=${filter?.endDate}`
  );
};

export const getRatioOfDelivery = (filter) => {
  return Http.get(
    filter?.userId
      ? `/messaging/reports/delivery-stats?startDate=${filter?.startDate}&endDate=${filter?.endDate}&userId=${filter?.userId}`
      : `/messaging/reports/delivery-stats?startDate=${filter?.startDate}&endDate=${filter?.endDate}`
  );
};

export const getCreditHistory = (filter) => {
  return Http.get(
    filter?.userId
      ? `/messaging/reports/credit-stats?startDate=${filter?.startDate}&endDate=${filter?.endDate}&userId=${filter?.userId}`
      : `/messaging/reports/credit-stats?startDate=${filter?.startDate}&endDate=${filter?.endDate}`
  );
};
