/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Form, Input, notification, Button as Btn } from "antd";
import Back from "../../../assets/imgs/back.png";
import Password from "../../../assets/icons/password.svg";
import { useNavigate } from "react-router-dom";
import AuthLayout from "../../../layouts/AuthLayout";
import Button from "../../../components/Buttons/Button";
import "../styles.scss";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import OtpInput from "react18-input-otp";
import PasswordField from "../../../components/Inputs/PasswordField";
import {
  UserForgotPassword,
  UserResetPassword,
} from "../../../redux/actions/auth.action";

const values = {
  newPassword: "",
  confirmPassword: "",
};

const ResetPassword = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get("email");
  const [form] = Form.useForm();
  const loader = useSelector((state) => state.auth.loading);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passwordsVisibility, setPasswordVisibility] = useState({
    newPassword: false,
    confirmPassword: false,
  });
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");

  const handleOtp = (enteredOtp) => {
    setOtp(enteredOtp);
    if (!enteredOtp) {
      setOtpError("OTP is required");
    } else if (enteredOtp?.length < 6) {
      setOtpError("Invalid OTP");
    } else {
      setOtpError("");
    }
  };

  const validateOTP = () => {
    if (!otp) {
      setOtpError("OTP is required");
    } else if (otp?.length < 6) {
      setOtpError("Invalid OTP");
    } else {
      setOtpError("");
    }
  };

  const resetPassword = (values) => {
    if (otpError) {
      return;
    }
    const data = { email, otp, password: values?.newPassword };
    dispatch(UserResetPassword(data))
      .then(async (res) => {
        notification.success({
          message: "Success",
          description:
            "Your password has been reset successful. Please login to continue",
        });
        navigate("/", { replace: true });
      })
      .catch((err) => {
        notification.error({
          message: "Error",
          description: err.response.data.errorMsg
            ? err.response.data.errorMsg
            : "Invalid OTP. Please ensure you enter correct OTP",
        });
      });
  };

  const forgotPassword = () => {
    dispatch(UserForgotPassword(email))
      .then(async (res) => {
        notification.success({
          message: "Success",
          description:
            "A password reset OTP has been sent to your phone number.",
        });
      })
      .catch((err) => {
        notification.error({
          message: "Error",
          description: err.response.data.errorMsg
            ? err.response.data.errorMsg
            : "An error has ocurred, please try again later",
        });
      });
  };

  useEffect(() => {
    if (!email) {
      navigate("/");
    }
  }, []);

  return (
    <AuthLayout>
      <div
        className={
          "h-full text-left flex flex-col w-full pt-10 pb-10 px-8 sm:px-16 lg:px-10 xl:px-16 relative overflow-auto"
        }
      >
        <Btn
          onClick={() => window.history.back()}
          className="border-none px-0 mb-[8%]"
        >
          <img src={Back} alt={"Back"} className={"h-6"} />
        </Btn>
        <div className={"text-sm-headline mb-2 text-white font-bold"}>
          RESET PASSWORD
        </div>
        <div className={"text-labels mb-[8%] text-white"}>
          Please enter the OTP sent to your phone and new password
        </div>
        <Formik
          initialValues={values}
          onSubmit={resetPassword}
          enableReinitialize={true}
          validationSchema={validator}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form onFinish={handleSubmit} form={form}>
              <div className={"mb-6"}>
                <div
                  className={
                    "text-left text-white font-normal text-md-regular mb-2 flex w-full justify-between"
                  }
                >
                  <p>Password</p>
                  <div className="flex gap-x-2 items-center text-labels">
                    <p>Did not receive OTP?</p>
                    <p
                      className={"text-primary-500 cursor-pointer"}
                      onClick={forgotPassword}
                    >
                      Resend
                    </p>
                  </div>
                </div>
                <div className="otp-container">
                  <OtpInput
                    className={"otp-input"}
                    value={otp}
                    onChange={handleOtp}
                    numInputs={6}
                  />
                  {otpError && (
                    <div className={"opt-error"} style={{ color: "#ff4d4f" }}>
                      {otpError}
                    </div>
                  )}
                </div>
              </div>
              <div className={"mb-4"}>
                <div
                  className={
                    "text-left text-white font-normal text-md-regular mb-2"
                  }
                >
                  <p>New Password</p>
                </div>
                <PasswordField
                  icon={Password}
                  iconPlacementCss={"iconCss"}
                  showPassword={passwordsVisibility?.newPassword}
                  handleShowPassword={(value) =>
                    setPasswordVisibility({
                      ...passwordsVisibility,
                      newPassword: value,
                    })
                  }
                >
                  <Form.Item
                    name={"newPassword"}
                    help={
                      touched.newPassword && errors.newPassword
                        ? errors.newPassword
                        : ""
                    }
                    validateStatus={
                      touched.newPassword && errors.newPassword
                        ? "error"
                        : "success"
                    }
                  >
                    <Input
                      type={
                        passwordsVisibility?.newPassword ? "text" : "password"
                      }
                      className={"form-field_input_5"}
                      placeholder={"Enter New Password"}
                      value={values?.newPassword || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Form.Item>
                </PasswordField>
              </div>
              <div className={"mb-4"}>
                <div
                  className={
                    "text-left text-white font-normal text-md-regular mb-2"
                  }
                >
                  <p>Confirm Password</p>
                </div>
                <PasswordField
                  icon={Password}
                  iconPlacementCss={"iconCss"}
                  showPassword={passwordsVisibility?.confirmPassword}
                  handleShowPassword={(value) =>
                    setPasswordVisibility({
                      ...passwordsVisibility,
                      confirmPassword: value,
                    })
                  }
                >
                  <Form.Item
                    name={"confirmPassword"}
                    help={
                      touched.confirmPassword && errors.confirmPassword
                        ? errors.confirmPassword
                        : ""
                    }
                    validateStatus={
                      touched.confirmPassword && errors.confirmPassword
                        ? "error"
                        : "success"
                    }
                  >
                    <Input
                      type={
                        passwordsVisibility?.confirmPassword
                          ? "text"
                          : "password"
                      }
                      className={"form-field_input_5"}
                      placeholder={"Retype Password"}
                      value={values?.confirmPassword || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Form.Item>
                </PasswordField>
              </div>
              <div className={"mb-[10%] mt-10 flex justify-center"}>
                <Button
                  css={"w-full"}
                  state={"PRIMARY"}
                  text={"Reset Password"}
                  type={"submit"}
                  loading={loader}
                  onClick={validateOTP}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </AuthLayout>
  );
};

const validator = yup.object().shape({
  newPassword: yup
    .string()
    .min(8, "Password should be a minimum of 8 characters")
    .required("New password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords must match")
    .required("Confirm password is required"),
});

export default ResetPassword;
