import { Types } from "../actions/auth.action";

const INITIAL_STATE = {
  loading: false,
};

export const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.AUTH_START:
    case Types.ACTIVATE_USER_START:
    case Types.CHANGE_PASSWORD_START:
    case Types.INVITE_USER_START:
    case Types.FORGOT_PASSWORD_START:
    case Types.RESET_PASSWORD_START:
      return { ...state, loading: true };
    case Types.AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case Types.ACTIVATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case Types.CHANGE_PASSWWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case Types.INVITE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case Types.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case Types.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case Types.AUTH_END:
    case Types.ACTIVATE_USER_END:
    case Types.CHANGE_PASSWORD_END:
    case Types.INVITE_USER_END:
    case Types.FORGOT_PASSWORD_END:
    case Types.RESET_PASSWORD_END:
      return { ...state, loading: false, message: action.message };
    default:
      return state;
  }
};
