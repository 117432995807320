export const flCapital = (s) =>
  s !== null ? s.charAt(0).toUpperCase() + s.slice(1) : "";

export const formatDate = (dateString) => {
  if (dateString) {
    const date = new Date(dateString);
    var _month = (date.getMonth() + 1).toString();
    var month = _month.length === 1 ? "0" + _month : _month;
    var _day = date.getDate().toString();
    var day = _day.length === 1 ? "0" + _day : _day;
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return day + "/" + month + "/" + date.getFullYear() + "  " + strTime;
  }
  return "";
};

export const localISOTime = (date) => {
  var x = new Date().getTimezoneOffset() * 60000;
  var isoTime = new Date(date - x).toISOString().slice(0, -1);
  return isoTime;
};

export const download = function (data) {
  const blob = new Blob([data], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("download", "download.csv");
  a.click();
};

export const csvmaker = function (data) {
  const csvRows = [];
  const headers = Object.keys(data);
  csvRows.push(headers.join(","));
  const values = Object.values(data).join(",");
  csvRows.push(values);
  return csvRows.join("\n");
};
