/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import "../styles.scss";
import { DatePicker, Select } from "antd";
import { useSelector } from "react-redux";
import FieldContainer from "../../../components/Inputs/FIeldContainer";

const { RangePicker } = DatePicker;

const options = {
  tooltip: {
    trigger: 'item'
  },
  legend: {
    orient: 'vertical',
    left: 'left',
    textStyle: {
      color: 'white'
    },
  },
  series: [
    {
      type: 'pie',
      radius: '100%',
      label: {
        color: "white",
      },
      data: [
        { value: 0, name: 'DELIVERED' },
        { value: 0, name: 'EXPIRED' },
        { value: 0, name: 'PROCESSING' },
        { value: 0, name: 'SENT' },
        { value: 0, name: 'UNDELIVERED' },
        { value: 0, name: 'UNKNOWN' }
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
  ]
};

const RatioOfDelivery = ({ setDateRange, user, setUser }) => {
  const loader = useSelector((state) => state?.reports?.deliveryLoading);
  const delivery = useSelector((state) => state?.reports?.delivery);
  const allUsers = useSelector((state) => state?.user?.allUsers);
  const [allUser, setAllUser] = useState([{ value: "", label: "All Users" }]);

  const [chartOptions, setChartOptions] = useState(options);

  useEffect(() => {
    setChartOptions({
      ...chartOptions,
    });
  }, []);

  useEffect(() => {
    let data = [
      { value: 0, name: 'DELIVERED' },
      { value: 0, name: 'EXPIRED' },
      { value: 0, name: 'PROCESSING' },
      { value: 0, name: 'SENT' },
      { value: 0, name: 'UNDELIVERED' },
      { value: 0, name: 'UNKNOWN' }
    ]
    if (delivery?.length) {
      data = delivery.map(d => {
        return { value: d?.total, name: d?.deliveryStatus?.toUpperCase() }
      })
    }
    setChartOptions({
      ...chartOptions,
      series: chartOptions.series?.map((s) => {
        return { ...s, data: data };
      }),
    });
  }, [delivery])

  const onChange = (date) => {
    if (date) {
      const startDate = date[0]
        ? new Date(date[0]).toISOString().split("T").join(" ")
        : "";
      const endDate = date[1]
        ? new Date(date[1]).toISOString().split("T").join(" ")
        : "";
      setDateRange({ startDate, endDate });
    } else {
      setDateRange({ startDate: "", endDate: "" });
    }
  };

  useEffect(() => {
    const users = allUsers.map(u => {
      return { value: u?.id, label: u?.fullname }
    });
    users?.unshift({ value: "", label: "All Users" });
    setAllUser(users);
  }, [allUsers])

  return (
    <div className="w-full bg-black trend-shadow rounded-lg text-sm-regular p-6 text-secondary-100">
      <div className="w-full flex justify-between items-center pb-4">
        <p className="font-semibold text-sm-headline">
          Ratio of Delivery
        </p>
        <div className="flex items-center gap-x-3">
          <div>
            <RangePicker
              onChange={onChange}
              className={"form-field_input_2 filter-select"}
            />
          </div>
          <div>
            <FieldContainer>
              <Select
                value={user}
                className={"form-field_input_2 filter-select"}
                onChange={(e) => {
                  setUser(e);
                }}
                placeholder="Select Role"
                options={allUser}
              />
            </FieldContainer>
          </div>
        </div>
      </div>
      <div className="w-full text-center mt-4">
        <ReactECharts showLoading={loader} option={chartOptions} />
      </div>
    </div>
  );
};

export default RatioOfDelivery;
