/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Form, Input, notification } from "antd";
import Email from "../../../assets/icons/email.svg";
import Password from "../../../assets/icons/password.svg";
import { NavLink, useNavigate } from "react-router-dom";
import AuthLayout from "../../../layouts/AuthLayout";
import FieldContainer from "../../../components/Inputs/FIeldContainer";
import Button from "../../../components/Buttons/Button";
import "../styles.scss";
import { Formik } from "formik";
import * as yup from "yup";
import PasswordField from "../../../components/Inputs/PasswordField";
import { useDispatch, useSelector } from "react-redux";
import { LoginUser } from "../../../redux/actions/auth.action";
import { FetchCurrentUser } from "../../../redux/actions/user.action";

const Login = () => {
  const [form] = Form.useForm();
  const loader = useSelector((state) => state.auth.loading);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passwordsVisibility, setPasswordVisibility] = useState(false);

  const [values, setValues] = useState({
    username: "",
    password: "",
  });

  const login = (values) => {
    dispatch(LoginUser(values))
      .then(async (res) => {
        const accessToken = res.data.jwt;
        const refreshToken = res.data.refreshToken;
        localStorage.setItem("vas_token", accessToken);
        localStorage.setItem("vas_refresh_token", refreshToken);
        localStorage.setItem("vas_login", values?.username);
        notification.success({
          message: "Success",
          description: "Login Successfully!",
        });
        await dispatch(FetchCurrentUser()).then((res) => {
          localStorage.setItem("vas_user", JSON.stringify(res.data.object));
        });
        navigate("/dashboard");
      })
      .catch((err) => {
        notification.error({
          message: "Error",
          description:
            err.response.data.status === 403
              ? "Invalid username or password"
              : "An error has ocurred, please try again later",
        });
      });
  };

  useEffect(() => {
    const token = localStorage.getItem("vas_token");
    if (token) {
      navigate("/dashboard");
    }
  }, []);

  useEffect(() => {
    const username = localStorage.getItem("vas_login");
    if (username !== null && username !== "") {
      form.setFieldsValue({ ...values, username });
      setValues({ ...values, username });
    }
  }, []);

  return (
    <AuthLayout>
      <div
        className={
          "h-full justify-center text-left flex flex-col w-full pt-20 pb-10 px-8 sm:px-16 lg:px-10 xl:px-16 relative"
        }
      >
        <div className={"text-sm-headline mb-2 text-white font-bold"}>
          LOGIN
        </div>
        <div className={"text-labels mb-[8%] text-white"}>
          Please login to continue
        </div>
        <Formik
          initialValues={values}
          onSubmit={login}
          enableReinitialize={true}
          validationSchema={validator}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form onFinish={handleSubmit} form={form}>
              <div className={"mb-4"}>
                <div
                  className={
                    "text-left text-white font-normal text-md-regular mb-2"
                  }
                >
                  <p>Email</p>
                </div>
                <FieldContainer icon={Email} iconPlacementCss={"iconCss"}>
                  <Form.Item
                    name={"username"}
                    help={
                      touched.username && errors.username ? errors.username : ""
                    }
                    validateStatus={
                      touched.username && errors.username ? "error" : "success"
                    }
                  >
                    <Input
                      type={"email"}
                      className={"form-field_input_4"}
                      placeholder={"Enter Email"}
                      value={values?.username || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Form.Item>
                </FieldContainer>
              </div>
              <div className={"mb-4"}>
                <div
                  className={
                    "text-left text-white font-normal text-md-regular mb-2 flex w-full justify-between"
                  }
                >
                  <p>Password</p>
                  <NavLink
                    className={"text-sm-regular text-primary-500"}
                    to={"/forgot-password"}
                  >
                    Forgot Password?
                  </NavLink>
                </div>
                <PasswordField
                  icon={Password}
                  iconPlacementCss={"iconCss"}
                  showPassword={passwordsVisibility}
                  handleShowPassword={(value) => setPasswordVisibility(value)}
                >
                  <Form.Item
                    name={"password"}
                    help={
                      touched.password && errors.password ? errors.password : ""
                    }
                    validateStatus={
                      touched.password && errors.password ? "error" : "success"
                    }
                  >
                    <Input
                      type={passwordsVisibility ? "text" : "password"}
                      className={"form-field_input_4"}
                      placeholder={"Enter Password"}
                      value={values?.password || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Form.Item>
                </PasswordField>
              </div>
              <div className={"mb-[10%] mt-10 flex justify-center"}>
                <Button
                  css={"w-full"}
                  state={"PRIMARY"}
                  text={"Login"}
                  type={"submit"}
                  loading={loader}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </AuthLayout>
  );
};

const validator = yup.object().shape({
  username: yup.string().required("Email is required"),
  password: yup.string().required("Password is required"),
});

export default Login;
