import {
  activateUser,
  changePassword,
  forgotPassword,
  inviteUser,
  login,
  resetPassword,
} from "../_api/index";

export const Types = {
  AUTH_START: "AUTH_START",
  AUTH_END: "AUTH_END",
  AUTH_SUCCESS: "AUTH_SUCCESS",

  ACTIVATE_USER_START: "ACTIVATE_USER_START",
  ACTIVATE_USER_END: "ACTIVATE_USER_END",
  ACTIVATE_USER_SUCCESS: "ACTIVATE_USER_SUCCESS",

  CHANGE_PASSWORD_START: "CHANGE_PASSWORD_START",
  CHANGE_PASSWORD_END: "CHANGE_PASSWORD_END",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",

  INVITE_USER_START: "INVITE_USER_START",
  INVITE_USER_END: "INVITE_USER_END",
  INVITE_USER_SUCCESS: "INVITE_USER_SUCCESS",

  FORGOT_PASSWORD_START: "FORGOT_PASSWORD_START",
  FORGOT_PASSWORD_END: "FORGOT_PASSWORD_END",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",

  RESET_PASSWORD_START: "RESET_PASSWORD_START",
  RESET_PASSWORD_END: "RESET_PASSWORD_END",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
};

export const LoginUser = (req) => (dispatch) => {
  dispatch({ type: Types.AUTH_START });
  return new Promise((resolve, reject) => {
    login(req)
      .then((res) => {
        dispatch({ type: Types.AUTH_SUCCESS });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.AUTH_END });
        reject(err);
      });
  });
};

export const ActivateUser = (params) => (dispatch) => {
  dispatch({ type: Types.ACTIVATE_USER_START });
  return new Promise((resolve, reject) => {
    activateUser(params)
      .then((res) => {
        dispatch({ type: Types.ACTIVATE_USER_SUCCESS });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.ACTIVATE_USER_END });
        reject(err);
      });
  });
};

export const ChangeUserPassword = (req) => (dispatch) => {
  dispatch({ type: Types.CHANGE_PASSWORD_START });
  return new Promise((resolve, reject) => {
    changePassword(req)
      .then((res) => {
        dispatch({ type: Types.CHANGE_PASSWORD_SUCCESS });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.CHANGE_PASSWORD_END });
        reject(err);
      });
  });
};

export const InviteUser = (req) => (dispatch) => {
  dispatch({ type: Types.INVITE_USER_START });
  return new Promise((resolve, reject) => {
    inviteUser(req)
      .then((res) => {
        dispatch({ type: Types.INVITE_USER_SUCCESS });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.INVITE_USER_END });
        reject(err);
      });
  });
};

export const UserForgotPassword = (email) => (dispatch) => {
  dispatch({ type: Types.FORGOT_PASSWORD_START });
  return new Promise((resolve, reject) => {
    forgotPassword(email)
      .then((res) => {
        dispatch({ type: Types.FORGOT_PASSWORD_SUCCESS });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.FORGOT_PASSWORD_END });
        reject(err);
      });
  });
};

export const UserResetPassword = (payload) => (dispatch) => {
  dispatch({ type: Types.RESET_PASSWORD_START });
  return new Promise((resolve, reject) => {
    resetPassword(payload)
      .then((res) => {
        dispatch({ type: Types.RESET_PASSWORD_SUCCESS });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.RESET_PASSWORD_END });
        reject(err);
      });
  });
};
