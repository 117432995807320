/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import "../styles.scss";
import FieldContainer from "../../../components/Inputs/FIeldContainer";
import { DatePicker, Select } from "antd";
import { useSelector } from "react-redux";

const { RangePicker } = DatePicker;

const options = {
  grid: { top: 8, right: 8, bottom: 24, left: 36 },
  xAxis: {
    type: "category",
    data: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
  yAxis: {
    type: "value",
  },
  series: [
    {
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      type: "line",
      smooth: true,
      lineStyle: { color: "#FEAD02" },
      barWidth: "10%",
      itemStyle: {
        color: "#FEAD02",
        borderRadius: [50, 50, 0, 0],
      },
      showSymbol: false,
    },
  ],
  tooltip: {
    trigger: "axis",
  },
};

const UsageTrends = ({ setDateRange, user, setUser }) => {
  const loader = useSelector((state) => state?.reports?.trendsLoading);
  const trends = useSelector((state) => state?.reports?.trends);
  const [chartType, setChartType] = useState("line");
  const allUsers = useSelector((state) => state?.user?.allUsers);
  const [allUser, setAllUser] = useState([{ value: "", label: "All Users" }]);

  const [chartOptions, setChartOptions] = useState(options);

  useEffect(() => {
    setChartOptions({
      ...chartOptions,
      series: chartOptions.series?.map((s) => {
        return { ...s, type: chartType };
      }),
    });
  }, [chartType]);

  useEffect(() => {
    const data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    for (let i = 0; i < trends?.length; i++) {
      data[trends[i].period - 1] = trends[i].total
    }
    setChartOptions({
      ...chartOptions,
      series: chartOptions.series?.map((s) => {
        return { ...s, data: data };
      }),
    });
  }, [trends])

  const onChange = (date) => {
    if (date) {
      const startDate = date[0]
        ? new Date(date[0]).toISOString().split("T").join(" ")
        : "";
      const endDate = date[1]
        ? new Date(date[1]).toISOString().split("T").join(" ")
        : "";
      setDateRange({ startDate, endDate });
    } else {
      setDateRange({ startDate: "", endDate: "" });
    }
  };

  useEffect(() => {
    const users = allUsers.map(u => {
      return { value: u?.id, label: u?.fullname }
    });
    users?.unshift({ value: "", label: "All Users" });
    setAllUser(users);
  }, [allUsers])

  return (
    <div className="w-full bg-black trend-shadow rounded-lg text-sm-regular p-6 text-secondary-100">
      <div className="w-full flex justify-between items-center">
        <p className="font-semibold text-sm-headline">
          Usage Trends
        </p>
        <div className="flex items-center gap-x-3">
          <div>
            <FieldContainer>
              <Select
                value={chartType}
                className={"form-field_input_2 filter-select"}
                onChange={(e) => {
                  setChartType(e);
                }}
                placeholder="Select Type"
                options={[
                  { value: "line", label: "Line Chart" },
                  { value: "bar", label: "Bar Char" },
                ]}
              />
            </FieldContainer>
          </div>
          <div>
            <RangePicker
              onChange={onChange}
              className={"form-field_input_2 filter-select"}
            />
          </div>
          <div>
            <FieldContainer>
              <Select
                value={user}
                className={"form-field_input_2 filter-select"}
                onChange={(e) => {
                  setUser(e);
                }}
                placeholder="Select Role"
                options={allUser}
              />
            </FieldContainer>
          </div>
        </div>
      </div>
      <div className="w-full text-center mt-4">
        <ReactECharts showLoading={loader} option={chartOptions} />
      </div>
    </div>
  );
};

export default UsageTrends;
