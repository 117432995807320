import React, { useState } from "react";
import "../styles.scss";
import MainLayout from "../../../layouts/MainLayout";
import SettingsMenu from "./SettingsMenu";
import ChangePassword from "./ChangePassword";
import CreateUser from "./CreateUser";

const Settings = () => {
  const [activeMenu, setActiveMenu] = useState("CHANGE_PASSWORD");
  return (
    <MainLayout title={"Settings"} isDashboard={false}>
      <div className={"flex flex-wrap"}>
        <div className={"xl:w-1/4 lg:w-2/5 w-full p-2"}>
          <SettingsMenu
            activeMenuValue={activeMenu}
            emitMenuValue={(m) => setActiveMenu(m)}
          />
        </div>
        <div className={"xl:w-3/4 lg:w-3/5 w-full p-2"}>
          <div className={"w-full settings-menu rounded-[8px] text-left p-4"}>
            {activeMenu === "CHANGE_PASSWORD" && <ChangePassword />}
            {activeMenu === "CREATE_USER" && <CreateUser />}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Settings;
