import { combineReducers } from "redux";

import { authReducer } from "./auth.reducers";
import { smsReducer } from "./messaging.reducer";
import { userReducer } from "./user.reducer";
import { creditReducer } from "./credit.reducer";
import { reportsReducer } from "./reports.reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  sms: smsReducer,
  user: userReducer,
  credit: creditReducer,
  reports: reportsReducer,
});

export default rootReducer;
