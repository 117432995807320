import React, { useState } from "react";
import { Form, Input, notification } from "antd";
import Password from "../../../assets/icons/password.svg";
import "../styles.scss";
import Button from "../../../components/Buttons/Button";
import { Formik } from "formik";
import * as yup from "yup";
import PasswordField from "../../../components/Inputs/PasswordField";
import { useDispatch, useSelector } from "react-redux";
import { ChangeUserPassword } from "../../../redux/actions/auth.action";

const values = {
  password: "",
  newPassword: "",
  confirmPassword: "",
};

const ChangePassword = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const loader = useSelector((state) => state?.auth?.loading);
  const [passwordsVisibility, setPasswordVisibility] = useState({
    password: false,
    newPassword: false,
    confirmPassword: false,
  });

  const changePassword = (values, { resetForm }) => {
    const user = JSON.parse(localStorage.getItem("vas_user"));
    const data = { ...values, email: user?.email };
    dispatch(ChangeUserPassword(data))
      .then(async (res) => {
        notification.success({
          message: "Success",
          description: "Password Changed Successfully!",
        });
        resetForm();
        form.resetFields();
      })
      .catch((err) => {
        notification.error({
          message: "Error",
          description: err.response.data.errorMsg
            ? err.response.data.errorMsg
            : "An error has ocurred, please try again later",
        });
      });
  };

  return (
    <div className={"w-full"}>
      <h2 className={"text-sm-headline font-bold text-white"}>
        Change Password
      </h2>
      <div className={"mt-10 w-full flex justify-center"}>
        <div className={"xl:w-1/2 w-full"}>
          <Formik
            initialValues={values}
            onSubmit={changePassword}
            enableReinitialize={true}
            validationSchema={validator}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form onFinish={handleSubmit} form={form}>
                <div className={"mb-4"}>
                  <div
                    className={
                      "text-left text-white font-normal text-md-regular mb-2"
                    }
                  >
                    <p>Password</p>
                  </div>
                  <PasswordField
                    icon={Password}
                    iconPlacementCss={"iconCss"}
                    showPassword={passwordsVisibility?.password}
                    handleShowPassword={(value) =>
                      setPasswordVisibility({
                        ...passwordsVisibility,
                        password: value,
                      })
                    }
                  >
                    <Form.Item
                      name={"password"}
                      help={
                        touched.password && errors.password
                          ? errors.password
                          : ""
                      }
                      validateStatus={
                        touched.password && errors.password
                          ? "error"
                          : "success"
                      }
                    >
                      <Input
                        type={
                          passwordsVisibility?.password ? "text" : "password"
                        }
                        className={"form-field_input_5"}
                        placeholder={"Enter Password"}
                        value={values?.password || ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Form.Item>
                  </PasswordField>
                </div>
                <div className={"mb-4"}>
                  <div
                    className={
                      "text-left text-white font-normal text-md-regular mb-2"
                    }
                  >
                    <p>New Password</p>
                  </div>
                  <PasswordField
                    icon={Password}
                    iconPlacementCss={"iconCss"}
                    showPassword={passwordsVisibility?.newPassword}
                    handleShowPassword={(value) =>
                      setPasswordVisibility({
                        ...passwordsVisibility,
                        newPassword: value,
                      })
                    }
                  >
                    <Form.Item
                      name={"newPassword"}
                      help={
                        touched.newPassword && errors.newPassword
                          ? errors.newPassword
                          : ""
                      }
                      validateStatus={
                        touched.newPassword && errors.newPassword
                          ? "error"
                          : "success"
                      }
                    >
                      <Input
                        type={
                          passwordsVisibility?.newPassword ? "text" : "password"
                        }
                        className={"form-field_input_5"}
                        placeholder={"Enter New Password"}
                        value={values?.newPassword || ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Form.Item>
                  </PasswordField>
                </div>
                <div className={"mb-4"}>
                  <div
                    className={
                      "text-left text-white font-normal text-md-regular mb-2"
                    }
                  >
                    <p>Confirm Password</p>
                  </div>
                  <PasswordField
                    icon={Password}
                    iconPlacementCss={"iconCss"}
                    showPassword={passwordsVisibility?.confirmPassword}
                    handleShowPassword={(value) =>
                      setPasswordVisibility({
                        ...passwordsVisibility,
                        confirmPassword: value,
                      })
                    }
                  >
                    <Form.Item
                      name={"confirmPassword"}
                      help={
                        touched.confirmPassword && errors.confirmPassword
                          ? errors.confirmPassword
                          : ""
                      }
                      validateStatus={
                        touched.confirmPassword && errors.confirmPassword
                          ? "error"
                          : "success"
                      }
                    >
                      <Input
                        type={
                          passwordsVisibility?.confirmPassword
                            ? "text"
                            : "password"
                        }
                        className={"form-field_input_5"}
                        placeholder={"Retype Password"}
                        value={values?.confirmPassword || ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Form.Item>
                  </PasswordField>
                </div>
                <div className={"mb-6 mt-10 flex justify-center"}>
                  <Button
                    css={"w-full"}
                    state={"PRIMARY"}
                    text={"Change Password"}
                    type={"submit"}
                    loading={loader}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

const validator = yup.object().shape({
  password: yup.string().required("Password is required"),
  newPassword: yup
    .string()
    .min(8, "Password should be a minimum of 8 characters")
    .required("New password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords must match")
    .required("Confirm password is required"),
});

export default ChangePassword;
