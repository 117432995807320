import { Types } from "../actions/reports.action";

const INITIAL_STATE = {
  trendsLoading: false,
  deliveryLoading: false,
  historyLoading: false,
  trends: [],
  delivery: [],
  history: [],
};

export const reportsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.FETCH_USAGE_TRENDS_START:
      return { ...state, trendsLoading: true };
    case Types.FETCH_RATIO_OF_DELIVERY_START:
      return { ...state, deliveryLoading: true };
    case Types.FETCH_CREDIT_HISTORY_START:
      return { ...state, historyLoading: true };
    case Types.FETCH_USAGE_TRENDS_SUCCESS:
      return {
        ...state,
        trendsLoading: false,
        trends: action.trends,
      };
    case Types.FETCH_RATIO_OF_DELIVERY_SUCCESS:
      return {
        ...state,
        deliveryLoading: false,
        delivery: action.delivery,
      };
    case Types.FETCH_CREDIT_HISTORY_SUCCESS:
      return {
        ...state,
        historyLoading: false,
        history: action.history,
      };
    case Types.FETCH_USAGE_TRENDS_END:
      return { ...state, trendsLoading: false, trends: action.trends };
    case Types.FETCH_RATIO_OF_DELIVERY_END:
      return { ...state, deliveryLoading: false, delivery: action.delivery };
    case Types.FETCH_CREDIT_HISTORY_END:
      return { ...state, historyLoading: false, history: action.history };
    default:
      return state;
  }
};
