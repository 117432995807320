import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.scss";
import Aos from "aos";
import "aos/dist/aos.css";
import Login from "./pages/Auth/Login";
import Dashboard from "./pages/Main/Dashboard";
import Settings from "./pages/Main/Settings";
import Users from "./pages/Main/Users";
import Activation from "./pages/Auth/Activation";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import ResetPassword from "./pages/Auth/ResetPassword";
import Messages from "./pages/Main/Messages";
import Send from "./pages/Main/Send";
import CreditManagement from "./pages/Main/CreditManagement";
import Reports from "./pages/Main/Reports";

function App() {
  useEffect(() => {
    Aos.init({
      offset: 200,
      duration: 1000,
      easing: "ease-in-sine",
      delay: 100,
    });
    document.title = "Vas Acquico | Messaging Platform";
  }, []);
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/activation" element={<Activation />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route exact path="/reset-password" element={<ResetPassword />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/send" element={<Send />} />
          <Route exact path="/messages" element={<Messages />} />
          <Route exact path="/settings" element={<Settings />} />
          <Route exact path="/reports" element={<Reports />} />
          <Route exact path="/users" element={<Users />} />
          <Route
            exact
            path="/credit-management"
            element={<CreditManagement />}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
