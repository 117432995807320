import { Card } from "antd";
import React, { useEffect, useState } from "react";
import "../styles.scss";

const gridStyle = {
  width: "100%",
  cursor: "pointer",
  textAlign: "left",
  padding: "16px",
};

const menu = [
  {
    id: 1,
    name: "Schedule",
    value: "SCHEDULE",
  },
  {
    id: 2,
    name: "Quick SMS",
    value: "QUICK_SMS",
  },
];

const SendMenu = ({ activeMenuValue, emitMenuValue }) => {
  const [activeMenu, setActiveMenu] = useState("");

  useEffect(() => {
    setActiveMenu(activeMenuValue);
  }, [activeMenuValue]);

  const handleMenu = (m) => {
    setActiveMenu(m);
    emitMenuValue(m);
  };

  return (
    <div className={"w-full rounded-full send-menu"}>
      <Card>
        {menu?.map((m, index) => (
          <Card.Grid
            onClick={() => handleMenu(m?.value)}
            key={m?.id + "menu"}
            className={`${
              index === 0
                ? activeMenu === m?.value
                  ? "send-menu-item-first bg-[#FEAD02] font-medium"
                  : "send-menu-item-first text-white"
                : index === menu?.length - 1
                ? activeMenu === m?.value
                  ? "send-menu-item-last bg-[#FEAD02] font-medium"
                  : "send-menu-item-last text-white"
                : activeMenu === m?.value
                ? "bg-gray-400 font-medium"
                : "text-white"
            }`}
            style={gridStyle}
          >
            {m?.name}
          </Card.Grid>
        ))}
      </Card>
    </div>
  );
};

export default SendMenu;
