import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL;

const Http = axios.create({ baseURL });

// let isRefreshing = false
async function refreshToken() {
  let req = localStorage.getItem("vas_refresh_token");
  await Http.post("/messaging/refreshtoken", { refreshToken: req })
    .then((response) => {
      if (response.status === 200) {
        localStorage.setItem(
          "vas_token",
          response.data.data.accessToken
        );
        localStorage.setItem(
          "vas_refresh_token",
          response.data.data.refreshToken
        );
      }
    })
    .catch((err) => {
      localStorage.clear();
      window.location.href = "/";
      return err;
    });
}

Http.interceptors.response.use(
  (res) => res,
  (err) => {
    const errObject = err.response;
    if (errObject.status === 401 || errObject.status === 403) {
      refreshToken();
    }

    return Promise.reject(err);
  }
);

Http.interceptors.request.use((config) => {
  const access_token = localStorage.getItem("vas_token");
  config.headers.Authorization = `Bearer ${access_token}`;
  return config;
});

export default Http;
