import React, { useEffect, useState } from "react";
import ClipArt from "../../assets/imgs/clip_art.png";
import Button from "../../components/Buttons/Button";
import "../styles.scss";

const MainLayoutHeader = ({
  title,
  description,
  triggerClick,
  isDashboard,
}) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("vas_user"));
    setUser(user);
  }, []);
  const handleClick = () => {
    triggerClick();
  };
  return (
    <div className={"w-full text-left"}>
      <div className={"flex justify-between items-center"}>
        <h2 className={"text-md-headline font-bold text-white"}>{title}</h2>
        {isDashboard ? (
          <div className="flex gap-x-4">
            {
              user?.role?.toUpperCase() !== "ADMIN" ?
                <div className={"flex gap-x-4 items-center"}>
                  <h2 className={"text-sm-headline font-normal text-white"}>
                    Available Credit:
                  </h2>
                  <h2 className={"text-sm-headline font-bold text-white"}>
                    {user?.credit?.toLocaleString()}
                  </h2>
                </div> : <></>
            }
            <Button
              css={"w-auto py-3 bg-primary-500 main-btn"}
              text={"Schedule"}
              type={"button"}
              onClick={handleClick}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      {isDashboard ? (
        <div
          className={
            "bg-gray-600 rounded-[12px] mt-8 pt-16 pb-10 px-8 relative"
          }
        >
          <p className={"text-white font-bold text-md-headline mb-2"}>
            Hi {user?.fullname}, Welcome!
          </p>
          {/* <p className={"text-white text-sm-regular"}>{description}</p> */}
          <img
            src={ClipArt}
            alt={"ClipArt"}
            className={
              "xl:h-[13rem] h-[10rem] top-[-10%] right-[5%] xl:top-[-34%] xl:right-[15%] clip-art lg:block hidden"
            }
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default MainLayoutHeader;
