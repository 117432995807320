import {
  getCreditHistory,
  getRatioOfDelivery,
  getUsageTrends,
} from "../_api/index";

export const Types = {
  FETCH_USAGE_TRENDS_START: "FETCH_USAGE_TRENDS_START",
  FETCH_USAGE_TRENDS_END: "FETCH_USAGE_TRENDS_END",
  FETCH_USAGE_TRENDS_SUCCESS: "FETCH_USAGE_TRENDS_SUCCESS",

  FETCH_RATIO_OF_DELIVERY_START: "FETCH_RATIO_OF_DELIVERY_START",
  FETCH_RATIO_OF_DELIVERY_END: "FETCH_RATIO_OF_DELIVERY_END",
  FETCH_RATIO_OF_DELIVERY_SUCCESS: "FETCH_RATIO_OF_DELIVERY_SUCCESS",

  FETCH_CREDIT_HISTORY_START: "FETCH_CREDIT_HISTORY_START",
  FETCH_CREDIT_HISTORY_END: "FETCH_CREDIT_HISTORY_END",
  FETCH_CREDIT_HISTORY_SUCCESS: "FETCH_CREDIT_HISTORY_SUCCESS",
};

export const FetchUsageTrends = (filter) => (dispatch) => {
  dispatch({ type: Types.FETCH_USAGE_TRENDS_START });
  return new Promise((resolve, reject) => {
    getUsageTrends(filter)
      .then((res) => {
        dispatch({
          type: Types.FETCH_USAGE_TRENDS_SUCCESS,
          trends: res.data.object,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.FETCH_USAGE_TRENDS_END });
        reject(err);
      });
  });
};

export const FetchRatioOfDelivery = (filter) => (dispatch) => {
  dispatch({ type: Types.FETCH_RATIO_OF_DELIVERY_START });
  return new Promise((resolve, reject) => {
    getRatioOfDelivery(filter)
      .then((res) => {
        dispatch({
          type: Types.FETCH_RATIO_OF_DELIVERY_SUCCESS,
          delivery: res.data.object,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.FETCH_RATIO_OF_DELIVERY_END });
        reject(err);
      });
  });
};

export const FetchCreditHistory = (filter) => (dispatch) => {
  dispatch({ type: Types.FETCH_CREDIT_HISTORY_START });
  return new Promise((resolve, reject) => {
    getCreditHistory(filter)
      .then((res) => {
        dispatch({
          type: Types.FETCH_CREDIT_HISTORY_SUCCESS,
          history: res.data.object,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.FETCH_CREDIT_HISTORY_END });
        reject(err);
      });
  });
};
