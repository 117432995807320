import React, { useEffect, useState } from "react";
import "../styles.scss";
import { Table, DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { FetchSMSBatches } from "../../../redux/actions/messaging.action";
import { formatDate } from "../../../utils/misc/constants";
// import { ReactComponent as TablerGrid } from "../../../assets/icons/icon-tabler-download.svg";

const { RangePicker } = DatePicker;

const Campaigns = ({ getCampaign }) => {
  const dispatch = useDispatch();
  const campaigns = useSelector((state) => state?.sms?.smsBatches);
  const loader = useSelector((state) => state?.sms?.loading);
  const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" });

  const [defaultCurrent, setDefaultCurrent] = useState(1);
  const [defaultPageSize, setDefaultPageSize] = useState(5);
  const [paginationData, setPaginationData] = useState({
    page: 0,
    size: 5,
  });

  useEffect(() => {
    dispatch(FetchSMSBatches({ ...paginationData, ...dateRange }));
  }, [dispatch, paginationData, dateRange]);

  const onChange = (date) => {
    if (date) {
      const startDate = date[0]
        ? new Date(date[0]).toISOString().split("T").join(" ")
        : "";
      const endDate = date[1]
        ? new Date(date[1]).toISOString().split("T").join(" ")
        : "";
      setDateRange({ startDate, endDate });
    } else {
      setDateRange({ startDate: "", endDate: "" });
    }
  };

  const formatIds = (ids) => {
    let id = "";
    for (let i = 0; i < ids.length; i++) {
      id = i !== ids.length - 1 ? id + ", " : id + "";
    }
    return id ? id : "Nil";
  }

  const columns = [
    {
      title: "Batch Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Batch Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Sender Id",
      dataIndex: "sender",
      key: "sender",
    },
    {
      title: "Recipient Count",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Schedule For",
      dataIndex: "scheduledAt",
      key: "scheduledAt",
    },
    {
      title: "Scheduled By",
      dataIndex: "scheduledBy",
      key: "scheduledBy",
    },
    // {
    //   title: "Action",
    //   dataIndex: "download",
    //   key: "download",
    // },
  ];

  const dataSource =
    campaigns &&
    campaigns?.content?.length > 0 &&
    campaigns?.content?.map((row) => {
      return {
        key: row.id,
        id: row?.id ?? "-",
        name: row?.name ?? "-",
        sender: row?.scheduledBy?.senderIds?.length ? formatIds(row?.scheduledBy?.senderIds) : "-",
        total: row?.total ?? "-",
        createdAt: row?.createdAt ? formatDate(row?.createdAt) : "22/08/2023 7:00 PM",
        scheduledAt: row?.scheduledAt ? formatDate(row?.scheduledAt) : "-",
        scheduledBy: row?.scheduledBy?.fullname ?? "-",
        // download: <div className="hover:text-primary-500" onClick={(e) => handleClick(e, row?.id)}><TablerGrid /></div>,
      };
    });

  const onShowSizeChange = (current, pageSize) => {
    if (pageSize || current) {
      setDefaultPageSize(pageSize);
      setDefaultCurrent(current - 1);
    } else {
      setDefaultPageSize(5);
      setDefaultCurrent(1);
    }
  };

  const paginationChangeHandler = (current, pageSize) => {
    setPaginationData({
      ...paginationData,
      page: current - 1,
      size: pageSize,
    });
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return (
        <a href={"#/"} className={"text-white"}>
          {"<"}
        </a>
      );
    }
    if (type === "next") {
      return (
        <a href={"#/"} className={"text-white"}>
          {">"}
        </a>
      );
    }
    return originalElement;
  };

  // const handleClick = (e, id) => {
  //   e.stopPropagation();
  //   console.log("HEY");
  //   console.log("HEY", id);
  // }

  return (
    <>
      <div className={"text-left"}>
        <h2 className={"text-sm-headline font-bold text-white"}>Campaigns</h2>
      </div>
      <div className={"flex justify-end mb-4 gap-x-4 mt-2"}>
        <RangePicker
          onChange={onChange}
          className={"xl:w-2/5 md:w-3/5 w-full form-field_input_0"}
        />
      </div>
      <Table
        loading={loader}
        dataSource={dataSource}
        columns={columns}
        style={{ cursor: "pointer" }}
        scroll={{ x: 800 }}
        onRow={(campaign) => {
          return {
            onClick: () => {
              getCampaign(campaign?.id);
            },
          };
        }}
        pagination={{
          pageSizeOptions: ["5", "10", "20", "30", "40"],
          showSizeChanger: true,
          onShowSizeChange: onShowSizeChange,
          defaultCurrent: defaultCurrent,
          total: campaigns?.totalElements,
          pageSize: defaultPageSize,
          showTotal: (total) => `Total ${total} items`,
          defaultPageSize: defaultPageSize,
          onChange: paginationChangeHandler,
          itemRender,
        }}
        className={"campaing-table"}
      />
    </>
  );
};

export default Campaigns;
