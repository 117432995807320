import {
  addSenderID,
  addUserCredit,
  deleteSenderID,
  getAllUsers,
  getCurrentUser,
  getUsers,
  setUserBalance,
} from "../_api/index";

export const Types = {
  FETCH_CURRENT_USER_START: "FETCH_CURRENT_USER_START",
  FETCH_CURRENT_USER_END: "FETCH_CURRENT_USER_END",
  FETCH_CURRENT_USER_SUCCESS: "FETCH_CURRENT_USER_SUCCESS",

  FETCH_USERS_START: "FETCH_USERS_START",
  FETCH_USERS_END: "FETCH_USERS_END",
  FETCH_USERS_SUCCESS: "FETCH_USERS_SUCCESS",

  ADD_USER_CREDIT_START: "ADD_USER_CREDIT_START",
  ADD_USER_CREDIT_END: "ADD_USER_CREDIT_END",
  ADD_USER_CREDIT_SUCCESS: "ADD_USER_CREDIT_SUCCESS",

  ADD_SENDER_ID_START: "ADD_SENDER_ID_START",
  ADD_SENDER_ID_END: "ADD_SENDER_ID_END",
  ADD_SENDER_ID_SUCCESS: "ADD_SENDER_ID_SUCCESS",

  DELETE_SENDER_ID_START: "DELETE_SENDER_ID_START",
  DELETE_SENDER_ID_END: "DELETE_SENDER_ID_END",
  DELETE_SENDER_ID_SUCCESS: "DELETE_SENDER_ID_SUCCESS",

  FETCH_ALL_USERS_START: "FETCH_ALL_USERS_START",
  FETCH_ALL_USERS_END: "FETCH_ALL_USERS_END",
  FETCH_ALL_USERS_SUCCESS: "FETCH_ALL_USERS_SUCCESS",

  SET_USER_BALANCE_START: "SET_USER_BALANCE_START",
  SET_USER_BALANCE_END: "SET_USER_BALANCE_END",
  SET_USER_BALANCE_SUCCESS: "SET_USER_BALANCE_SUCCESS",
};

export const FetchCurrentUser = () => (dispatch) => {
  dispatch({ type: Types.FETCH_CURRENT_USER_START });
  return new Promise((resolve, reject) => {
    getCurrentUser()
      .then((res) => {
        dispatch({
          type: Types.FETCH_CURRENT_USER_SUCCESS,
          currentUser: res.data.object,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.FETCH_CURRENT_USER_END });
        reject(err);
      });
  });
};

export const FetchUsers = (filter) => (dispatch) => {
  dispatch({ type: Types.FETCH_USERS_START });
  return new Promise((resolve, reject) => {
    getUsers(filter)
      .then((res) => {
        dispatch({
          type: Types.FETCH_USERS_SUCCESS,
          users: res.data.object,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.FETCH_USERS_END });
        reject(err);
      });
  });
};

export const AddUserCredit = (req) => (dispatch) => {
  dispatch({ type: Types.ADD_USER_CREDIT_START });
  return new Promise((resolve, reject) => {
    addUserCredit(req)
      .then((res) => {
        dispatch({ type: Types.ADD_USER_CREDIT_SUCCESS });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.ADD_USER_CREDIT_END });
        reject(err);
      });
  });
};

export const AddSenderID = (req) => (dispatch) => {
  dispatch({ type: Types.ADD_SENDER_ID_START });
  return new Promise((resolve, reject) => {
    addSenderID(req)
      .then((res) => {
        dispatch({ type: Types.ADD_SENDER_ID_SUCCESS });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.ADD_SENDER_ID_END });
        reject(err);
      });
  });
};

export const DeleteSenderID = (req) => (dispatch) => {
  dispatch({ type: Types.DELETE_SENDER_ID_START });
  return new Promise((resolve, reject) => {
    deleteSenderID(req)
      .then((res) => {
        dispatch({ type: Types.DELETE_SENDER_ID_SUCCESS });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.DELETE_SENDER_ID_END });
        reject(err);
      });
  });
};

export const FetchAllUsers = () => (dispatch) => {
  dispatch({ type: Types.FETCH_ALL_USERS_START });
  return new Promise((resolve, reject) => {
    getAllUsers()
      .then((res) => {
        dispatch({
          type: Types.FETCH_ALL_USERS_SUCCESS,
          allUsers: res.data.object,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.FETCH_ALL_USERS_END });
        reject(err);
      });
  });
};

export const SetUserBalance = (req) => (dispatch) => {
  dispatch({ type: Types.SET_USER_BALANCE_START });
  return new Promise((resolve, reject) => {
    setUserBalance(req)
      .then((res) => {
        dispatch({ type: Types.SET_USER_BALANCE_SUCCESS });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.SET_USER_BALANCE_END });
        reject(err);
      });
  });
};
