import React, { useEffect, useState } from "react";
import {
    Button as Btn, Spin, notification,
} from "antd";
import "../styles.scss";
import Back from "../../../assets/imgs/back.png";
import { formatDate } from "../../../utils/misc/constants";
import Button from "../../../components/Buttons/Button";
import { useDispatch } from "react-redux";
import { DeleteSenderID } from "../../../redux/actions/user.action";

const LEFT_COL = "border-b border-l border-r border-gray-600 py-2 text-center";
const RIGHT_COL = "border-b border-r border-gray-600 py-2 text-center text-[12px] text-primary-500 cursor-pointer";

const UserDetails = ({ setView, user, setUserId, setUserName }) => {
    const dispatch = useDispatch();
    const [userIds, setUserIds] = useState([]);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        const ids = [];
        for (let i = 0; i < user?.senderIds?.length; i++) {
            if (user?.senderIds[i]) {
                ids.push(user?.senderIds[i])
            }
        }
        setUserIds(ids);
    }, [user])

    const deleteSenderID = (values) => {
        const data = {
            senderIds: [values],
            id: user?.id,
        };
        setLoader(true);
        dispatch(DeleteSenderID(data))
            .then(async (res) => {
                setLoader(false);
                notification.success({
                    message: "Success",
                    description: "Sender ID Deleted Successfully!",
                });
                const ids = userIds.filter(uu => uu !== values);
                setUserIds(ids);
            })
            .catch((err) => {
                setLoader(false);
                notification.error({
                    message: "Error",
                    description: err?.response?.data?.errorMsg
                        ? err?.response?.data?.errorMsg
                        : "An error has ocurred, please try again later",
                });
            });
    };

    return (
        <div>
            <div className={"flex items-center"}>
                <div className={"flex gap-x-4 items-center"}>
                    <Btn
                        onClick={() => setView(0)}
                        className={"border-none px-0"}
                    >
                        <img src={Back} alt={"Back"} className={"h-6"} />
                    </Btn>
                </div>
            </div>
            <h2 className={"text-md-headline font-extrabold text-white mt-12 text-left"}>
                Account Details
            </h2>
            <div className="mt-10 w-full text-white text-left">
                <div className={"flex"}>
                    <div className="w-3/12">Name</div>
                    <div className="w-9/12">{user?.fullname}</div>
                </div>
                <div className={"flex mt-6"}>
                    <div className="w-3/12">Created Date</div>
                    <div className="w-9/12">{user?.createdAt ? formatDate(user?.createdAt) : "-"}</div>
                </div>
                <div className={"flex mt-6"}>
                    <div className="w-3/12">Email</div>
                    <div className="w-9/12">{user?.email}</div>
                </div>
                <div className={"flex mt-6"}>
                    <div className="w-3/12">Account Type</div>
                    <div className="w-9/12">{user?.role}</div>
                </div>
                <div className={"flex mt-6 items-center"}>
                    <div className="w-3/12">Credit</div>
                    <div className="w-6/12">{user?.credit}</div>
                </div>
                <div className="flex mt-6 gap-x-6">
                    <Button
                        css={"w-max messages-btn"}
                        text={"Add Credit"}
                        type={"button"}
                        onClick={() => { setUserId(user.id); setView(1); setUserName(user?.fullname ?? "-") }}
                        state={"PRIMARY"}
                    />
                    <Button
                        css={"w-max messages-btn"}
                        text={"Add Sender ID"}
                        type={"button"}
                        onClick={() => { setUserId(user.id); setView(3); setUserName(user?.fullname ?? "-") }}
                        state={"PRIMARY"}
                    />
                    <Button
                        css={"w-max messages-btn"}
                        text={"Set Balance"}
                        type={"button"}
                        onClick={() => { setUserId(user.id); setView(4); setUserName(user?.fullname ?? "-") }}
                        state={"PRIMARY"}
                    />
                </div>
            </div>
            <h2 className={"text-md-headline font-extrabold text-white mt-20 text-left mb-4"}>
                Sender IDs
            </h2>
            <Spin spinning={loader} delay={500}>
                <div className="w-full text-white text-left">
                    <div className={"w-full lg:mt-0 mt-4"}>
                        <div
                            className={
                                "w-full flex flex-wrap text-sm-regular text-white"
                            }
                        >
                            <div className={"w-full flex border text-center py-1 border-gray-600 bg-gray-600 font-bold text-white"}>
                                <div className={"w-1/2"}>
                                    <div className={LEFT_COL}>Sender ID</div>
                                </div>
                                <div className={"w-1/2"}>
                                    <div className={'border-b border-r border-gray-600 py-2 text-center'}>Action</div>
                                </div>
                            </div>
                            {userIds?.map(ii => <div key={ii} className={"w-full flex py-1"}>
                                <div className={"w-1/2"}>
                                    <div className={LEFT_COL}>{ii}</div>
                                </div>
                                <div className={"w-1/2"}>
                                    <div className={RIGHT_COL} onClick={() => deleteSenderID(ii)}>Remove</div>
                                </div>
                            </div>)}
                        </div>
                    </div>
                </div>
            </Spin>
        </div>
    );
};

export default UserDetails;
