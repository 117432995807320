import { Types } from "../actions/user.action";

const INITIAL_STATE = {
  loading: false,
  currentUser: {},
  users: {},
  allUsers: [],
};

export const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.FETCH_CURRENT_USER_START:
    case Types.FETCH_USERS_START:
    case Types.ADD_USER_CREDIT_START:
    case Types.SET_USER_BALANCE_START:
    case Types.ADD_SENDER_ID_START:
    case Types.DELETE_SENDER_ID_START:
    case Types.FETCH_ALL_USERS_START:
      return { ...state, loading: true };
    case Types.FETCH_CURRENT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.currentUser,
      };
    case Types.FETCH_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.users,
      };
    case Types.ADD_USER_CREDIT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case Types.SET_USER_BALANCE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case Types.ADD_SENDER_ID_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case Types.DELETE_SENDER_ID_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case Types.FETCH_ALL_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        allUsers: action.allUsers,
      };
    case Types.FETCH_CURRENT_USER_END:
    case Types.FETCH_USERS_END:
    case Types.ADD_USER_CREDIT_END:
    case Types.SET_USER_BALANCE_END:
    case Types.ADD_SENDER_ID_END:
    case Types.DELETE_SENDER_ID_END:
    case Types.FETCH_ALL_USERS_END:
      return { ...state, loading: false, message: action.message };
    default:
      return state;
  }
};
