import React, { useState } from "react";
import {
  Form,
  Input,
  Upload,
  Button as Btn,
  DatePicker,
  notification,
} from "antd";
import "../styles.scss";
import Button from "../../../components/Buttons/Button";
import { Formik } from "formik";
import * as yup from "yup";
import FieldContainer from "../../../components/Inputs/FIeldContainer";
import { UploadOutlined } from "@ant-design/icons";
import Download from "../../../assets/imgs/downloads.png";
import Sample from "./campaign.csv";
import { useDispatch, useSelector } from "react-redux";
import { ScheduleCampaign } from "../../../redux/actions/messaging.action";
import { localISOTime } from "../../../utils/misc/constants";

const Schedule = () => {
  const [form] = Form.useForm();
  const loader = useSelector((state) => state.sms.loading);
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    file: null,
    campaignName: "",
    scheduleDate: null,
  });

  const scheduleCampaign = (values, { resetForm }) => {
    const formData = new FormData();
    const data = {
      name: values?.campaignName,
      scheduledAt: localISOTime(new Date(values?.scheduleDate))
        .split("T")
        .join(" "),
    };
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    formData.append("csvfile", values?.file);
    formData.append("batch", JSON.stringify(data));

    dispatch(ScheduleCampaign(formData, config))
      .then(async (res) => {
        notification.success({
          message: "Success",
          description: "Campaign Uploaded Successfully!",
        });
        resetForm();
        form.resetFields();
        setValues({
          file: null,
          campaignName: "",
          scheduleDate: null,
        });
      })
      .catch((err) => {
        notification.error({
          message: "Error",
          description: err.response.data.errorMsg
            ? err.response.data.errorMsg
            : "An error has ocurred, please try again later",
        });
      });
  };

  return (
    <div className={"w-full"}>
      <div className={"flex flex-wrap w-full justify-between items-center"}>
        <h2
          className={"text-sm-regular md:text-sm-headline font-bold text-white"}
        >
          Schedule a campaign
        </h2>
        <a
          href={Sample}
          download={"Sample.csv"}
          className={
            "button_light gap-x-2 lg:gap-x-4 text-labels lg:text-sm-regular py-2 lg:text-labels xl:px-4 px-1 shadow-md md:mt-0 mt-4 md:w-auto w-full"
          }
        >
          <img src={Download} alt={"icon"} className={"h-2"} />
          Download Sample
        </a>
      </div>
      <div className={"mt-10 w-full flex justify-center text-left"}>
        <div className={"md:w-1/2 w-full"}>
          <Formik
            initialValues={values}
            onSubmit={scheduleCampaign}
            enableReinitialize={true}
            validationSchema={validator}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form onFinish={handleSubmit} form={form}>
                <div className={"mb-4 text-left"}>
                  <div
                    className={
                      "text-left text-white font-normal text-md-regular mb-2"
                    }
                  >
                    <p>CSV File</p>
                  </div>
                  <FieldContainer>
                    <Form.Item
                      name={"file"}
                      help={touched.file && errors.file ? errors.file : ""}
                      validateStatus={
                        touched.file && errors.file ? "error" : "success"
                      }
                      valuePropName={"file"}
                    >
                      <Upload
                        beforeUpload={(file) => {
                          setValues({ ...values, file });
                          return false;
                        }}
                        onChange={handleChange}
                        multiple={false}
                        listType={"picture"}
                        className={"w-full upload"}
                        maxCount={1}
                        accept={".csv"}
                      >
                        <Btn
                          className={
                            "w-full h-[3rem] flex items-center justify-center"
                          }
                          icon={<UploadOutlined />}
                        >
                          Browse
                        </Btn>
                      </Upload>
                    </Form.Item>
                  </FieldContainer>
                </div>
                <div className={"mb-4"}>
                  <div
                    className={
                      "text-left text-white font-normal text-md-regular mb-2"
                    }
                  >
                    <p>Campaign Name</p>
                  </div>
                  <FieldContainer iconPlacementCss={"iconCss"}>
                    <Form.Item
                      name={"campaignName"}
                      help={
                        touched.campaignName && errors.campaignName
                          ? errors.campaignName
                          : ""
                      }
                      validateStatus={
                        touched.campaignName && errors.campaignName
                          ? "error"
                          : "success"
                      }
                    >
                      <Input
                        type={"text"}
                        className={"form-field_input_2"}
                        placeholder={"Enter Campaign Name"}
                        value={values?.campaignName || null}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Form.Item>
                  </FieldContainer>
                </div>
                <div className={"mb-4"}>
                  <div
                    className={
                      "text-left text-white font-normal text-md-regular mb-2"
                    }
                  >
                    <p>Schedule Date</p>
                  </div>
                  <FieldContainer iconPlacementCss={"iconCss"}>
                    <Form.Item
                      name={"scheduleDate"}
                      help={
                        touched.scheduleDate && errors.scheduleDate
                          ? errors.scheduleDate
                          : ""
                      }
                      validateStatus={
                        touched.scheduleDate && errors.scheduleDate
                          ? "error"
                          : "success"
                      }
                    >
                      <DatePicker
                        showTime
                        className={"form-field_input_2"}
                        placeholder={"Enter Schedule Date"}
                        value={values?.scheduleDate || {}}
                        onChange={(e) =>
                          setValues({
                            ...values,
                            scheduleDate: e ? new Date(e) : null,
                          })
                        }
                        onOk={(e) =>
                          setValues({
                            ...values,
                            scheduleDate: e ? new Date(e) : null,
                          })
                        }
                        onBlur={handleBlur}
                        disabledDate={(d) =>
                          !d ||
                          d.isBefore(
                            new Date(Date.now()).toISOString().split("T")[0]
                          )
                        }
                      />
                    </Form.Item>
                  </FieldContainer>
                </div>
                <div className={"mb-6 mt-10 flex justify-center"}>
                  <Button
                    css={"w-full"}
                    state={"PRIMARY"}
                    text={"Submit"}
                    type={"submit"}
                    loading={loader}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

const validator = yup.object().shape({
  file: yup.mixed().required("CSV file is required"),
  campaignName: yup
    .string()
    .min(6, "Campaign name should be minimum of 6 characters")
    .required("Email is required"),
  scheduleDate: yup.mixed().required("Schedule date is required"),
});

export default Schedule;
