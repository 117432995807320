import React, { useState } from "react";
import {
    Form,
    Input,
    Button as Btn,
    notification,
} from "antd";
import "../styles.scss";
import Button from "../../../components/Buttons/Button";
import Back from "../../../assets/imgs/back.png";
import { Formik } from "formik";
import * as yup from "yup";
import FieldContainer from "../../../components/Inputs/FIeldContainer";
import { useDispatch, useSelector } from "react-redux";
import { AddUserCredit } from "../../../redux/actions/user.action";

const AddCredit = ({ setView, userName, userId }) => {
    const [form] = Form.useForm();
    const loader = useSelector((state) => state.sms.loading);
    const dispatch = useDispatch();

    const [values, setValues] = useState({
        credit: "",
    });

    const addCredit = (values, { resetForm }) => {
        const data = {
            credit: values?.credit,
            id: userId,
        };

        dispatch(AddUserCredit(data))
            .then(async (res) => {
                notification.success({
                    message: "Success",
                    description: "Credit Added Successfully!",
                });
                resetForm();
                form.resetFields();
                setValues({
                    credit: "",
                });
                setView(0);
            })
            .catch((err) => {
                notification.error({
                    message: "Error",
                    description: err.response.data.errorMsg
                        ? err.response.data.errorMsg
                        : "An error has ocurred, please try again later",
                });
            });
    };

    return (
        <div>
            <div className={"text-left flex justify-between items-center"}>
                <div className={"flex gap-x-4 items-center"}>
                    <Btn
                        onClick={() => setView(0)}
                        className={"border-none px-0"}
                    >
                        <img src={Back} alt={"Back"} className={"h-6"} />
                    </Btn>
                    <h2 className={"text-sm-headline font-bold text-white"}>
                        {userName}
                    </h2>
                </div>
                <h2 className={"text-sm-headline font-bold text-white"}>
                    Add Credit
                </h2>
            </div>
            <div className={"mt-10 w-full flex justify-center text-left"}>
                <div className={"md:w-1/2 w-full"}>
                    <Formik
                        initialValues={values}
                        onSubmit={addCredit}
                        enableReinitialize={true}
                        validationSchema={validator}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => (
                            <Form onFinish={handleSubmit} form={form}>
                                <div className={"mb-4"}>
                                    <div
                                        className={
                                            "text-left text-white font-normal text-md-regular mb-2"
                                        }
                                    >
                                        <p>Credit</p>
                                    </div>
                                    <FieldContainer iconPlacementCss={"iconCss"}>
                                        <Form.Item
                                            name={"credit"}
                                            help={
                                                touched.credit && errors.credit
                                                    ? errors.credit
                                                    : ""
                                            }
                                            validateStatus={
                                                touched.credit && errors.credit
                                                    ? "error"
                                                    : "success"
                                            }
                                        >
                                            <Input
                                                type={"number"}
                                                className={"form-field_input_2"}
                                                placeholder={"Enter Credit"}
                                                value={values?.credit || null}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </Form.Item>
                                    </FieldContainer>
                                </div>
                                <div className={"mb-6 mt-10 flex justify-center"}>
                                    <Button
                                        css={"w-full"}
                                        state={"PRIMARY"}
                                        text={"Submit"}
                                        type={"submit"}
                                        loading={loader}
                                    />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

const validator = yup.object().shape({
    credit: yup
        .number()
        .min(1, "Credit should be more than 0")
        .required("Credit is required"),
});

export default AddCredit;
