import React, { useEffect, useState } from "react";
import { Form, Input, Select, notification } from "antd";
import "../styles.scss";
import Button from "../../../components/Buttons/Button";
import { Field, Formik } from "formik";
import * as yup from "yup";
import FieldContainer from "../../../components/Inputs/FIeldContainer";
import { useDispatch, useSelector } from "react-redux";
import { SendQuickSMS } from "../../../redux/actions/messaging.action";

const { TextArea } = Input;

const values = {
  name: "",
  phonenumber: "",
  message: "",
};

const QuickSMS = () => {
  const [form] = Form.useForm();
  const loader = useSelector((state) => state.auth.loading);
  const dispatch = useDispatch();
  const [userIds, setUserIds] = useState([]);
  const user = JSON.parse(localStorage.getItem("vas_user"));

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("vas_user"));
    if (user?.senderIds?.length) {
      const ids = [];
      for (let i = 0; i < user?.senderIds?.length; i++) {
        if (user?.senderIds[i]) {
          ids.push({ value: user?.senderIds[i], label: user?.senderIds[i] })
        }
      }
      setUserIds(ids);
    }
  }, [])

  const sendQuickSMS = (values, { resetForm }) => {
    const data = {
      ...values,
      senderId: user?.id,
      phonenumber: values?.phonenumber?.replace(/\s/g, ""),
    };
    dispatch(SendQuickSMS(data))
      .then(async (res) => {
        notification.success({
          message: "Success",
          description: "SMS Sent Successfully!",
        });
        resetForm();
        form.resetFields();
      })
      .catch((err) => {
        notification.error({
          message: "Error",
          description: err.response.data.errorMsg
            ? err.response.data.errorMsg
            : "An error has ocurred, please try again later",
        });
      });
  };

  return (
    <div className={"w-full"}>
      <h2 className={"text-sm-headline font-bold text-white"}>Quick SMS</h2>
      <div className={"mt-10 w-full flex justify-center"}>
        <div className={"xl:w-1/2 w-full"}>
          <Formik
            initialValues={values}
            onSubmit={sendQuickSMS}
            enableReinitialize={true}
            validationSchema={validator}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form onFinish={handleSubmit} form={form}>
                <div className={"mb-4"}>
                  <div
                    className={
                      "text-left text-white font-normal text-md-regular mb-2"
                    }
                  >
                    <p>Sender ID</p>
                  </div>
                  <FieldContainer iconPlacementCss={"iconCss"}>
                    <Form.Item
                      name={"name"}
                      help={touched.name && errors.name ? errors.name : ""}
                      validateStatus={
                        touched.name && errors.name ? "error" : "success"
                      }
                    >
                      <Field>
                        {({ form: { setFieldValue } }) => (
                          <Select
                            value={values?.name || undefined}
                            onBlur={handleBlur}
                            className={"form-field_input_2"}
                            onChange={(e) => {
                              setFieldValue("name", e);
                            }}
                            placeholder="Choose Sender ID"
                            options={userIds}
                          />
                        )}
                      </Field>
                    </Form.Item>
                  </FieldContainer>
                  {/* <FieldContainer iconPlacementCss={"iconCss"}>
                    <Form.Item
                      name={"name"}
                      help={touched.name && errors.name ? errors.name : ""}
                      validateStatus={
                        touched.name && errors.name ? "error" : "success"
                      }
                    >
                      <Input
                        type={"text"}
                        className={"form-field_input_2"}
                        placeholder={"Enter Name"}
                        value={values?.name || ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Form.Item>
                  </FieldContainer> */}
                </div>
                <div className={"mb-4"}>
                  <div
                    className={
                      "text-left text-white font-normal text-md-regular mb-2"
                    }
                  >
                    <p>Phone Number</p>
                  </div>
                  <FieldContainer iconPlacementCss={"iconCss"}>
                    <Form.Item
                      name={"phonenumber"}
                      help={
                        touched.phonenumber && errors.phonenumber
                          ? errors.phonenumber
                          : ""
                      }
                      validateStatus={
                        touched.phonenumber && errors.phonenumber
                          ? "error"
                          : "success"
                      }
                    >
                      <Input
                        type={"tel"}
                        className={"form-field_input_2"}
                        placeholder={"E.g +23412341234,+23412341234"}
                        value={values?.phonenumber || ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Form.Item>
                  </FieldContainer>
                </div>
                <div className={"mb-4"}>
                  <div
                    className={
                      "text-left text-white font-normal text-md-regular mb-2"
                    }
                  >
                    <p>Message</p>
                  </div>
                  <FieldContainer iconPlacementCss={"iconCss"}>
                    <Form.Item
                      name={"message"}
                      help={
                        touched.message && errors.message ? errors.message : ""
                      }
                      validateStatus={
                        touched.message && errors.message ? "error" : "success"
                      }
                    >
                      <TextArea
                        type={"text"}
                        className={"form-field_input_x"}
                        placeholder={"Enter Message"}
                        value={values?.message || ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        rows={4}
                        showCount
                      />
                    </Form.Item>
                  </FieldContainer>
                </div>
                <div className={"mb-6 mt-10 flex justify-center"}>
                  <Button
                    css={"w-full"}
                    state={"PRIMARY"}
                    text={"Send"}
                    type={"submit"}
                    loading={loader}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

const validator = yup.object().shape({
  // name: yup
  //   .string()
  //   .min(6, "Name should be minimum of 6 characters")
  //   .required("Name is required"),
  name: yup
    .string()
    .required("Sender ID is required"),
  phonenumber: yup
    .string()
    .test(
      "max-delimited-phone",
      "Phone number cannot be more than 10",
      function (value) {
        const valArray = value.split(",");
        return valArray.length < 11;
      }
    )
    .required("Phone number is required"),
  message: yup
    .string()
    .min(6, "Message should be minimum of 6 characters")
    .required("Message is required"),
});

export default QuickSMS;
