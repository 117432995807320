/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Form, Input, notification, Button as Btn } from "antd";
import Email from "../../../assets/icons/email.svg";
import Back from "../../../assets/imgs/back.png";
import { useNavigate } from "react-router-dom";
import AuthLayout from "../../../layouts/AuthLayout";
import FieldContainer from "../../../components/Inputs/FIeldContainer";
import Button from "../../../components/Buttons/Button";
import "../styles.scss";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { UserForgotPassword } from "../../../redux/actions/auth.action";

const ForgotPassword = () => {
  const [form] = Form.useForm();
  const loader = useSelector((state) => state.auth.loading);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const values = { email: "" };

  const forgotPassword = (values) => {
    dispatch(UserForgotPassword(values?.email))
      .then(async (res) => {
        notification.success({
          message: "Success",
          description:
            "A password reset OTP has been sent to your phone number.",
        });
        navigate(`/reset-password?email=${values?.email}`);
      })
      .catch((err) => {
        notification.error({
          message: "Error",
          description: err.response.data.errorMsg
            ? err.response.data.errorMsg
            : "An error has ocurred, please try again later",
        });
      });
  };

  return (
    <AuthLayout>
      <div
        className={
          "h-full justify-center text-left flex flex-col w-full pt-20 pb-10 px-8 sm:px-16 lg:px-10 xl:px-16 relative"
        }
      >
        <Btn
          onClick={() => window.history.back()}
          className="border-none px-0 mb-[8%]"
        >
          <img src={Back} alt={"Back"} className={"h-6"} />
        </Btn>
        <div className={"text-sm-headline mb-2 text-white font-bold"}>
          FORGOT PASSWORD
        </div>
        <div className={"text-sm-regular mb-[8%] text-white"}>
          Please enter your email address
        </div>
        <Formik
          initialValues={values}
          onSubmit={forgotPassword}
          enableReinitialize={true}
          validationSchema={validator}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form onFinish={handleSubmit} form={form}>
              <div className={"mb-4"}>
                <div
                  className={
                    "text-left text-white font-normal text-md-regular mb-2"
                  }
                >
                  <p>Email</p>
                </div>
                <FieldContainer icon={Email} iconPlacementCss={"iconCss"}>
                  <Form.Item
                    name={"email"}
                    help={touched.email && errors.email ? errors.email : ""}
                    validateStatus={
                      touched.email && errors.email ? "error" : "success"
                    }
                  >
                    <Input
                      type={"email"}
                      className={"form-field_input_4"}
                      placeholder={"Enter Email"}
                      value={values?.email || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Form.Item>
                </FieldContainer>
              </div>
              <div className={"mb-[10%] mt-10 flex justify-center"}>
                <Button
                  css={"w-full"}
                  state={"PRIMARY"}
                  text={"Submit"}
                  type={"submit"}
                  loading={loader}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </AuthLayout>
  );
};

const validator = yup.object().shape({
  email: yup.string().required("Email is required"),
});

export default ForgotPassword;
