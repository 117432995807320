import { Button as Btn, Input, Table, notification } from "antd";
import React, { useEffect, useState } from "react";
import Back from "../../../assets/imgs/back.png";
import "../styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { DownloadCampaignMessages, FetchCampaignMessages } from "../../../redux/actions/messaging.action";
import FieldContainer from "../../../components/Inputs/FIeldContainer";
import Button from "../../../components/Buttons/Button";
import { debounce } from "lodash";
import { download, formatDate } from "../../../utils/misc/constants";

const CampaignMessages = ({ campaignId, removeCampaignId }) => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState({ phoneNumber: "" });
  const campaignMessages = useSelector((state) => state?.sms?.campaignMessages);
  const loader = useSelector((state) => state?.sms?.messagesLoading);
  const [defaultCurrent, setDefaultCurrent] = useState(1);
  const [defaultPageSize, setDefaultPageSize] = useState(5);
  const [paginationData, setPaginationData] = useState({
    page: 0,
    size: 5,
  });

  useEffect(() => {
    dispatch(FetchCampaignMessages(campaignId, paginationData, query));
  }, [dispatch, campaignId, paginationData, query]);

  const columns = [
    {
      title: "Phone",
      dataIndex: "phonenumber",
      key: "phonenumber",
    },
    {
      title: "Text",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Sent At",
      dataIndex: "sendAt",
      key: "sendAt",
    },
    {
      title: "Delivery Receipt Time",
      dataIndex: "dlrTimestamp",
      key: "dlrTimestamp",
    },
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   key: "id",
    // },
  ];

  const dataSource =
    campaignMessages &&
    campaignMessages?.content?.length > 0 &&
    campaignMessages?.content?.map((row) => {
      return {
        key: row.id,
        phonenumber: row?.phonenumber ?? "-",
        message: row?.message ?? "-",
        status: row?.status?.toUpperCase() ?? "-",
        id: row?.id ?? "-",
        sendAt: row?.sendAt ? formatDate(row?.sendAt) : "-",
        dlrTimestamp: row?.dlrTimestamp ? formatDate(row?.dlrTimestamp) : "-",
      };
    });

  const onShowSizeChange = (current, pageSize) => {
    if (pageSize || current) {
      setDefaultPageSize(pageSize);
      setDefaultCurrent(current - 1);
    } else {
      setDefaultPageSize(5);
      setDefaultCurrent(1);
    }
  };

  const paginationChangeHandler = (current, pageSize) => {
    setPaginationData({
      ...paginationData,
      page: current - 1,
      size: pageSize,
    });
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return (
        <a href={"#/"} className={"text-white"}>
          {"<"}
        </a>
      );
    }
    if (type === "next") {
      return (
        <a href="#/" className={"text-white"}>
          {">"}
        </a>
      );
    }
    return originalElement;
  };

  const performSearch = debounce((value) => {
    if (value.length > 0) {
      setQuery({ phoneNumber: value });
    } else {
      setQuery({ phoneNumber: "" });
    }
  }, 700);

  const handleChange = (event) => {
    performSearch(event?.target?.value);
  };

  const handleDownload = () => {
    dispatch(DownloadCampaignMessages(campaignId, paginationData, query)).then(async (res) => {
      download(res?.data);
    })
      .catch((err) => {
        notification.error({
          message: "Error",
          description: err.response.data.errorMsg
            ? err.response.data.errorMsg
            : "An error has ocurred, please try again later",
        });
      });;
  };

  return (
    <>
      <div className={"text-left flex gap-x-4 items-center"}>
        <Btn
          onClick={() => removeCampaignId(null)}
          className={"border-none px-0"}
        >
          <img src={Back} alt={"Back"} className={"h-6"} />
        </Btn>
        <h2 className={"text-sm-headline font-bold text-white"}>
          Campaign Messages
        </h2>
      </div>
      <div className={"mt-10"}>
        <div className="flex justify-between mb-2">
          <div className={"xl:w-2/5 md:w-3/5 w-full"}>
            <FieldContainer iconPlacementCss={"iconCss"}>
              <Input
                type={"text"}
                className={"form-field_input_2"}
                placeholder={"Search by phone number"}
                onChange={handleChange}
              />
            </FieldContainer>
          </div>
          <div className="flex items-center">
            <Button
              css={"w-auto py-3 messages-btn"}
              text={"Download"}
              type={"button"}
              onClick={() => handleDownload()}
              state={"PRIMARY"}
            />
          </div>
        </div>
        <Table
          loading={loader}
          dataSource={dataSource}
          columns={columns}
          scroll={{ x: 800 }}
          pagination={{
            pageSizeOptions: ["5", "10", "20", "30", "40"],
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
            defaultCurrent: defaultCurrent,
            total: campaignMessages?.totalElements,
            pageSize: defaultPageSize,
            showTotal: (total) => `Total ${total} items`,
            defaultPageSize: defaultPageSize,
            onChange: paginationChangeHandler,
            itemRender,
          }}
          className={"campaing-table"}
        />
      </div>
    </>
  );
};

export default CampaignMessages;
