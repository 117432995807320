import React, { useState } from "react";
import {
    Form,
    Button as Btn,
    notification,
    Input,
} from "antd";
import "../styles.scss";
import Button from "../../../components/Buttons/Button";
import Back from "../../../assets/imgs/back.png";
import { Formik } from "formik";
import * as yup from "yup";
import FieldContainer from "../../../components/Inputs/FIeldContainer";
import { useDispatch } from "react-redux";
import { AddSenderID } from "../../../redux/actions/user.action";

const AddSenderId = ({ setView, userName, userId }) => {
    const [form] = Form.useForm();
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();

    const [values, setValues] = useState({
        senderIds: "",
    });

    const addSenderID = (values, { resetForm }) => {
        const data = {
            senderIds: [values?.senderIds],
            id: userId,
        };
        setLoader(true);
        dispatch(AddSenderID(data))
            .then(async (res) => {
                setLoader(false);
                notification.success({
                    message: "Success",
                    description: "Sender ID Added Successfully!",
                });
                resetForm();
                form.resetFields();
                setValues({
                    senderIds: "",
                });
                setView(0);
            })
            .catch((err) => {
                setLoader(false);
                notification.error({
                    message: "Error",
                    description: err.response.data.errorMsg
                        ? err.response.data.errorMsg
                        : "An error has ocurred, please try again later",
                });
            });
    };

    return (
        <div>
            <div className={"text-left flex justify-between items-center"}>
                <div className={"flex gap-x-4 items-center"}>
                    <Btn
                        onClick={() => setView(0)}
                        className={"border-none px-0"}
                    >
                        <img src={Back} alt={"Back"} className={"h-6"} />
                    </Btn>
                    <h2 className={"text-sm-headline font-bold text-white"}>
                        {userName}
                    </h2>
                </div>
                <h2 className={"text-sm-headline font-bold text-white"}>
                    Add Sender ID
                </h2>
            </div>
            <div className={"mt-10 w-full flex justify-center text-left"}>
                <div className={"md:w-1/2 w-full"}>
                    <Formik
                        initialValues={values}
                        onSubmit={addSenderID}
                        enableReinitialize={true}
                        validationSchema={validator}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => (
                            <Form onFinish={handleSubmit} form={form}>
                                <div className={"mb-4"}>
                                    <div
                                        className={
                                            "text-left text-white font-normal text-md-regular mb-2"
                                        }
                                    >
                                        <p>Sender ID</p>
                                    </div>
                                    <FieldContainer iconPlacementCss={"iconCss"}>
                                        <Form.Item
                                            name={"senderIds"}
                                            help={
                                                touched.senderIds && errors.senderIds
                                                    ? errors.senderIds
                                                    : ""
                                            }
                                            validateStatus={
                                                touched.senderIds && errors.senderIds
                                                    ? "error"
                                                    : "success"
                                            }
                                        >
                                            <Input
                                                type={"text"}
                                                className={"form-field_input_2"}
                                                placeholder={"Enter Sender ID"}
                                                value={values?.senderIds || ""}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </Form.Item>
                                    </FieldContainer>
                                </div>
                                <div className={"mb-6 mt-10 flex justify-center"}>
                                    <Button
                                        css={"w-full"}
                                        state={"PRIMARY"}
                                        text={"Submit"}
                                        type={"submit"}
                                        loading={loader}
                                    />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

const validator = yup.object().shape({
    senderIds: yup
        .string()
        .required("Sender ID is required"),
});

export default AddSenderId;
