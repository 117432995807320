import { Types } from "../actions/messaging.action";

const INITIAL_STATE = {
  loading: false,
  messagesLoading: false,
  smsBatches: {},
  campaignDetails: {},
  campaignSummary: {},
  campaignMessages: {},
  messages: {},
};

export const smsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.SMS_BATCHES_START:
    case Types.CAMPAIGN_DETAILS_START:
    case Types.CAMPAIGN_SUMMARY_START:
    case Types.SCHEDULE_CAMPAIGN_START:
    case Types.MESSAGES_START:
    case Types.SEND_QUICK_SMS_START:
    case Types.DOWNLOAD_CAMPAIGN_MESSAGES_START:
      return { ...state, loading: true };
    case Types.SMS_BATCHES_SUCCESS:
      return {
        ...state,
        loading: false,
        smsBatches: action.smsBatches,
      };
    case Types.CAMPAIGN_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        campaignDetails: action.campaignDetails,
      };
    case Types.CAMPAIGN_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        campaignSummary: action.campaignSummary,
      };
    case Types.SCHEDULE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case Types.CAMPAIGN_MESSAGES_START:
      return { ...state, messagesLoading: true };
    case Types.CAMPAIGN_MESSAGES_SUCCESS:
      return {
        ...state,
        messagesLoading: false,
        campaignMessages: action.campaignMessages,
      };
    case Types.MESSAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        messages: action.messages,
      };
    case Types.SEND_QUICK_SMS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case Types.DOWNLOAD_CAMPAIGN_MESSAGES_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case Types.CAMPAIGN_MESSAGES_END:
      return { ...state, messagesLoading: false, message: action.message };
    case Types.SMS_BATCHES_END:
    case Types.CAMPAIGN_DETAILS_END:
    case Types.CAMPAIGN_SUMMARY_END:
    case Types.SCHEDULE_CAMPAIGN_END:
    case Types.MESSAGES_END:
    case Types.SEND_QUICK_SMS_END:
    case Types.DOWNLOAD_CAMPAIGN_MESSAGES_END:
      return { ...state, loading: false, message: action.message };
    default:
      return state;
  }
};
