import React, { useEffect, useState } from "react";
import "../styles.scss";
import { Table, Input } from "antd";
import FieldContainer from "../../../components/Inputs/FIeldContainer";
import Search from "../../../assets/imgs/search.png";
import { useDispatch, useSelector } from "react-redux";
import { FetchUsers } from "../../../redux/actions/user.action";
import { formatDate } from "../../../utils/misc/constants";
import { debounce } from "lodash";
import Button from "../../../components/Buttons/Button";

const UserList = ({ setUserId, setView, setUserName, getUser }) => {
    const dispatch = useDispatch();
    const [query, setQuery] = useState({ name: "" });
    const users = useSelector((state) => state?.user?.users);
    const loader = useSelector((state) => state?.user?.loading);

    const [defaultCurrent, setDefaultCurrent] = useState(1);
    const [defaultPageSize, setDefaultPageSize] = useState(5);
    const [paginationData, setPaginationData] = useState({
        page: 0,
        size: 5,
    });

    useEffect(() => {
        dispatch(FetchUsers({ ...paginationData, ...query }));
    }, [dispatch, paginationData, query]);

    const handleChange = (event) => {
        performSearch(event?.target?.value);
    };

    const performSearch = debounce((value) => {
        if (value.length > 0) {
            setQuery({ name: value });
        } else {
            setQuery({ name: "" });
        }
    }, 700);

    const columns = [
        {
            title: "User ID",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "Name",
            dataIndex: "fullname",
            key: "fullname",
        },
        {
            title: "Role",
            dataIndex: "role",
            key: "role",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Date Created",
            dataIndex: "createdAt",
            key: "createdAt",
        },
        {
            title: "Credit Available",
            dataIndex: "credit",
            key: "credit",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
        },
    ];

    const dataSource =
        users &&
        users?.content?.length > 0 &&
        users?.content?.map((row) => {
            return {
                key: row.id,
                id: row?.id ?? "-",
                fullname: row?.fullname ?? "-",
                role: row?.role ?? "-",
                email: row?.email ?? "-",
                createdAt: row?.createdAt ? formatDate(row?.createdAt) : "-",
                credit: row?.credit?.toLocaleString() ?? "-",
                status: row?.status?.toString()?.toUpperCase() ?? "-",
                action: <Button
                    css={"w-max py-2 messages-btn !px-2"}
                    text={"Add Credit"}
                    type={"button"}
                    onClick={() => { setUserId(row.id); setView(1); setUserName(row?.fullname ?? "-") }}
                    state={"PRIMARY"}
                />,
            };
        });

    const onShowSizeChange = (current, pageSize) => {
        if (pageSize || current) {
            setDefaultPageSize(pageSize);
            setDefaultCurrent(current - 1);
        } else {
            setDefaultPageSize(5);
            setDefaultCurrent(1);
        }
    };

    const paginationChangeHandler = (current, pageSize) => {
        setPaginationData({
            ...paginationData,
            page: current - 1,
            size: pageSize,
        });
    };

    const itemRender = (_, type, originalElement) => {
        if (type === "prev") {
            return (
                <a href={"#/"} className={"text-white"}>
                    {"<"}
                </a>
            );
        }
        if (type === "next") {
            return (
                <a href={"#/"} className={"text-white"}>
                    {">"}
                </a>
            );
        }
        return originalElement;
    };

    return (
        <div>
            <div className={"text-left"}>
                <h2 className={"text-sm-headline font-bold text-white"}>Users</h2>
            </div>
            <div className={"flex justify-end mb-4 gap-x-4"}>
                <div className={"xl:w-1/4 md:w-2/4 w-full md:mt-0 mt-4"}>
                    <FieldContainer icon={Search}>
                        <Input
                            type={"email"}
                            className={"form-field_input_1"}
                            placeholder={"Search"}
                            onChange={handleChange}
                        />
                    </FieldContainer>
                </div>
            </div>
            <Table
                loading={loader}
                dataSource={dataSource}
                columns={columns}
                style={{ cursor: "pointer" }}
                scroll={{ x: 800 }}
                onRow={(row) => {
                    return {
                        onClick: () => {
                            setView(2);
                            getUser(users?.content?.find(user => user?.id === row?.id))
                        },
                    };
                }}
                pagination={{
                    pageSizeOptions: ["5", "10", "20", "30", "40"],
                    showSizeChanger: true,
                    onShowSizeChange: onShowSizeChange,
                    defaultCurrent: defaultCurrent,
                    total: users?.totalElements,
                    pageSize: defaultPageSize,
                    showTotal: (total) => `Total ${total} items`,
                    defaultPageSize: defaultPageSize,
                    onChange: paginationChangeHandler,
                    itemRender,
                }}
                className={"campaing-table"}
            />
        </div>
    );
};

export default UserList;
